import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import CountUp from "react-countup";
import { useViewport } from "../hooks/useViewPort";

interface StatisticsWidgetProps {
  variant: string;
  description: string;
  stats: string;
  icon: string;
  counterOptions?: any;
  hideIconOnMobile?: boolean;
}

const StatisticsWidget = (props: StatisticsWidgetProps) => {
  const { width } = useViewport();

  const hideIconCol = props.hideIconOnMobile ? width < 900 : false;

  const forcedStatisticTitleFont =
    width < 1030
      ? Number(props.stats) >= 100000
        ? "font-15"
        : "font-18"
      : "font-18";

  return (
    <>
      <Card
        className={classNames(
          "widget-rounded-circle ",
          hideIconCol ? "border-" + props.variant : ""
        )}
        style={
          hideIconCol
            ? { border: "1px solid" }
            : { border: "1px solid #333333" }
        }
      >
        <Card.Body>
          <Row className="align-items-stretch">
            {!hideIconCol && (
              <Col className="col-4">
                <div
                  className={classNames(
                    props.hideIconOnMobile ? "avatar-md" : "avatar-lg",
                    "rounded-circle",
                    "bg-soft-" + props["variant"],
                    "border-" + props["variant"],
                    "border"
                  )}
                >
                  <i
                    className={classNames(
                      props["icon"],
                      "avatar-title",
                      "font-22",
                      "text-" + props["variant"]
                    )}
                  ></i>
                </div>
              </Col>
            )}
            <Col className={hideIconCol ? "col-12" : "col-8"}>
              <div className="text-end">
                <h3
                  className={classNames(
                    "text-dark mt-1 ",
                    forcedStatisticTitleFont
                  )}
                >
                  <span>
                    <CountUp
                      duration={1}
                      end={props["stats"]}
                      {...props["counterOptions"]}
                    />
                  </span>
                </h3>
                <p className="text-muted mb-1 text-truncate">
                  {props["description"]}
                </p>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default StatisticsWidget;
