import React from "react";
import Select from "react-select";
import { Box } from "@chakra-ui/react";

interface OperationSelectProps {
  data: string[];
  onChange: (option: any) => void;
  placeholder?: string;
}

const OperationSelect = ({
  data,
  onChange,
  placeholder = "Selecione uma operação",
}: OperationSelectProps) => {
  const options = data
    ? data.map((item) => ({ value: item, label: item }))
    : [];

  return (
    <Select
      placeholder={placeholder}
      options={options}
      isSearchable
      isClearable
      onChange={onChange}
    />
  );
};

export default OperationSelect;
