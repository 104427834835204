import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import DatePicker from "../../../components/Datepicker";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Badge,
  Box,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { format, parseISO } from "date-fns";
import {
  CheckIcon,
  NotAllowedIcon,
  RepeatClockIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Recargas",
  path: "/base/recharge/",
  apiBase: "/bancas/recharge/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {format(parseISO(props?.value), "dd/MM/yyyy HH:mm:ss")}
    </React.Fragment>
  );
};

const columns = [
  {
    id: "idUnitPayment",
    Header: "ID",
    accessor: "idUnitPayment",
    sort: true,
    classes: "fw-semibold",
  },
  {
    id: "createdAt",
    Header: "Data criação",
    accessor: "createdAt",
    sort: true,
    Cell: DateColumn,
  },
  {
    Header: "ID unidade",
    accessor: "idUnit",
    sort: false,
    classes: "fw-semibold",
  },
  {
    Header: "Unidade",
    accessor: "fullName",
    sort: false,
    classes: "fw-semibold",
  },
  {
    id: "amount",
    Header: "Valor",
    accessor: "amount",
    sort: true,
    classes: "fw-semibold",
    Cell: ({ value }) => {
      const formattedValue = formatCurrency(value);
      return <span>R$ {formattedValue}</span>;
    },
  },
  {
    Header: "TxID",
    accessor: "provider_uuid",
    sort: false,
    classes: "fw-semibold",
    Cell: ({ row, value }) => {
      const provider = row?.original?.provider?.name;
      return (
        <span>
          <b>{provider}</b> <br />
          {value}
        </span>
      );
    },
  },
  {
    Header: "Origem",
    accessor: "account",
    sort: true,
    classes: "fw-semibold",
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    sort: true,
    classes: "fw-semibold",
    Cell: ({ value }) => (
      <Badge
        bgColor={value === "pending" ? "#f39c12" : "#00a65a"}
        color="white"
        width={"100%"}
        textAlign={"center"}
      >
        {value === "paid" ? "PAGO" : "PENDENTE"}
      </Badge>
    ),
  },
];

/**
 * COMPONENT
 */
const Recargas = () => {
  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const [dataSelect, setDataSelect] = useState<any>(null);
  const [formUnit, setFormUnit] = useState<string>("");
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formStatus, setFormStatus] = useState<string>("");

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */

  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));
    try {
      await api.get("/bancas/bff/select-data", null).then((response) => {
        setDataSelect(response.data ?? []);
      });

      await handleSearch({});
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  }, [setData]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const handleSearch = async ({
    page,
    field,
    order,
    search,
  }: {
    page?: number;
    field?: string;
    order?: string;
    search?: string;
  }) => {
    const payload = {
      startDate: formStartDate,
      status: formStatus,
      unit: formUnit,
      page,
      field,
      order,
      search,
    };
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados de recarga.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const handlePageChange = async ({
    page,
    field,
    order,
    search,
  }: {
    page?: number;
    field?: string;
    order?: string;
    search?: string;
  }) => {
    return await handleSearch({
      page,
      field,
      order,
      search,
    });
  };
  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Row>
            <Col sm={4}>
              <StatisticsWidget
                variant="success"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                description="Efetivadas"
                stats={data?.stats?.efetivadas || 0}
                icon="mdi mdi-currency-usd"
              />
            </Col>
            <Col sm={4}>
              <StatisticsWidget
                variant="danger"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                description="Pendentes"
                stats={data?.stats?.pendentes || 0}
                icon="fe-bar-chart-line"
              />
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <Row>
                <Text fontWeight={"bold"}>Período:</Text>
                <Col sm={12}>
                  <Col sm={6} className="mb-2">
                    <Input
                      className="form-control"
                      type="date"
                      value={formStartDate}
                      onChange={(e) => setFormStartDate(e.target.value)}
                    />
                  </Col>
                  <Col sm={6} className="mb-2">
                    <Select
                      placeholder="Todas as unidades"
                      value={formUnit}
                      onChange={(e) => setFormUnit(e.target.value)}
                    >
                      <optgroup label={"Regiões"}>
                        {dataSelect?.regions.map((item: any) => (
                          <option value={"R:" + item.idRegion}>
                            {item.name}
                          </option>
                        ))}
                      </optgroup>
                      <optgroup label={"Cobradores"}>
                        {dataSelect?.collectors.map((item: any) => (
                          <option value={"P:" + item.idUnit}>
                            {item.fullName}
                          </option>
                        ))}
                      </optgroup>
                    </Select>
                  </Col>
                  <Col sm={6} className="mb-2">
                    <Select
                      placeholder="Todos status"
                      value={formStatus}
                      onChange={(e) => setFormStatus(e.target.value)}
                    >
                      <option value="paid">Pagos</option>
                      <option value="pending">Pendentes</option>
                    </Select>
                  </Col>
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch({ page: 1 })}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>

              <Table
                isCalculate={true}
                calculateInput={"amount"}
                isSearchable={true}
                columns={columns}
                data={data.data || []}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-hover table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Recargas;
