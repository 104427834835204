import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Badge,
  Box,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  Divider,
  Flex,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  StackDivider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Center,
  Checkbox,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { format } from "date-fns";
import { sumTotalValue } from "../../../helpers/sum";
import LoaderSimple from "../../../components/LoaderSimple";
import Select from "react-select";
import StatisticsWidget from "../../../components/StatisticsWidget";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Geral",
  path: "/base/geral/",
  update: "/base/geral/update/",
  apiBaseV2: "/bancas/geral-new/",
  apiBase: "/bancas/geral/",
};

/**
 * COMPONENT
 */
const GeralV2 = () => {
  /**
   * HOOKS
   */
  const [data, setData] = useState([]);
  const toast = useToast();
  const [dataSelect, setDataSelect] = useState<any>(null);
  const [financeIsLoading, setFinanceIsLoading] = useState(false);
  const [finance, setFinance] = useState({});
  const [geralIsLoading, setGeralIsLoading] = useState(false);
  const [formUnit, setFormUnit] = useState<string>("");
  const [formUnitType, setFormUnitType] = useState<string>("");
  const [games, setGames] = useState<string[]>(["LOTERIAS", "FAZENDINHA"]);
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [manualData, setManualData] = useState<any>({});

  const handleSelectGame = (typeGame: string) => {
    setGames(
      (prevGames) =>
        prevGames.includes(typeGame)
          ? prevGames.filter((game) => game !== typeGame) // Remove se já estiver na lista
          : [...prevGames, typeGame] // Adiciona se não estiver
    );
  };

  const { permission: perm } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));

  const permission = perm?.title;

  const openManualBalance = async (idUnit, type, value) => {
    if (value <= 0) {
      return;
    }

    let payload = {
      startDate: formStartDate,
      endDate: formEndDate,
      idUnit,
      type,
    };

    await api
      .create(configs.apiBase + "manual", payload)
      .then((response) => {
        setManualData(response.data ?? []);
        onOpen();
      })
      .catch((error) => {
        toast({
          title: "Erro ao buscar registros.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });

    console.log(idUnit);
  };

  const fetchDataBasic = async () => {
    setGeralIsLoading(true);
    try {
      await api.get("/bancas/bff/select-data", null).then((response) => {
        setDataSelect(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setGeralIsLoading(false);
  };

  const handlePageLoading = useCallback(async () => {
    await fetchDataBasic();
    // await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const preparePayload = (type: string) => {
    let dates = {
      startDate: formStartDate,
      endDate: formEndDate,
    };
    let payload = {
      ...dates,
      games,
      unit: formUnit,
      type: formUnitType,
    };
    const hoje = format(new Date(), "yyyy-MM-dd");
    const decreaseDate = (qtd: number) => {
      const date = new Date();
      date.setDate(date.getDate() - qtd);
      return format(date, "yyyy-MM-dd");
    };
    if (type === "hoje") {
      payload = {
        ...payload,
        startDate: hoje,
        endDate: hoje,
      };
    } else if (type === "ontem") {
      payload = {
        ...payload,
        startDate: decreaseDate(1),
        endDate: decreaseDate(1),
      };
    } else if (type === "7d") {
      payload = {
        ...payload,
        startDate: decreaseDate(7),
        endDate: hoje,
      };
    } else if (type === "30d") {
      payload = {
        ...payload,
        startDate: decreaseDate(30),
        endDate: hoje,
      };
    } else if (type === "mes") {
      payload = {
        ...payload,
        startDate: hoje.split("-")[0] + "-" + hoje.split("-")[1] + "-01",
        endDate: hoje,
      };
    }
    setFormStartDate(payload.startDate);
    setFormEndDate(payload.endDate);
    return payload;
  };

  const handleSearch = async (type: string) => {
    const payload = preparePayload(type);
    setGeralIsLoading(true);
    setFinanceIsLoading(true);
    try {
      await api
        .create(configs.apiBaseV2 + "units", payload)
        .then((response) => {
          setData(response.data ?? []);
        });
      setGeralIsLoading(false);

      // if (permission === "Admin - Base")
      await api
        .create(configs.apiBase + "finance", payload)
        .then((response) => {
          setFinance(response.data ?? []);
        });
      setFinanceIsLoading(false);
    } catch (e) {
      toast({
        title: "Erro ao buscar registros",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setGeralIsLoading(false);
    setFinanceIsLoading(false);
  };

  const calcAll = (field: string, format: string) => {
    const total = sumTotalValue(data, field) || 0;
    if (format === "raw") {
      return total;
    } else {
      return formatCurrency(total);
    }
  };

  const options = [
    {
      label: "TODAS AS UNIDADES",
      options: [
        {
          label: "TODAS AS UNIDADES",
          value: "",
        },
      ],
    },
    {
      label: "Regiões",
      options:
        dataSelect?.regions?.map((item: any) => {
          return {
            label: item.name.toUpperCase(),
            value: "R:" + item.idRegion,
          };
        }) || [],
    },
    {
      label: "Promotores",
      options:
        dataSelect?.collectors?.map((item: any) => {
          return {
            label: item.fullName.toUpperCase(),
            value: "P:" + item.idUnit,
          };
        }) || [],
    },
  ];

  /**
   * TABLE DATE COLUMN
   */
  const DateColumn = ({ ...props }: any) => {
    return (
      <React.Fragment>
        {new Date(props?.value).toLocaleDateString("pt-BR")}
      </React.Fragment>
    );
  };

  /**
   * TABLE ACTIONS
   */
  const ActionColumn = ({ ...props }: any) => {
    const object = props?.row?.values;
    const dispatch = useDispatch<AppDispatch>();

    /**
     * CUSTOM HOOKS
     */
    const { publish } = useEvent();
    const toast = useToast();

    /**
     * HANDLERS
     */
  };

  const columnsGeral = [
    {
      id: "idUnit",
      Header: "ID",
      accessor: "idUnit",
      sort: true,
    },
    {
      id: "fullName",
      Header: "Unidade",
      accessor: "fullName",
      sort: true,
      Cell: ({ row }: any) => {
        const item = row.original;

        enum EnumType {
          "Promotor/Unidade" = "green",
          "Repasse/Unidade" = "#c0392b",
          "Unidade" = "blue",
        }

        return (
          <>
            {item.fullName} <br />
            {item.name && (
              <>
                {item.name} <br />
              </>
            )}
            {item.type === "Promotor/Unidade" && (
              <Badge colorScheme={EnumType[item.type]}>{item.type}</Badge>
            )}
          </>
        );
      },
    },
    {
      id: "sells",
      Header: "Vendas",
      accessor: "sells",
      sort: true,
      Cell: ({ value, row }) => {
        const data = row?.original;
        const sell = data.sells;
        return (
          <>
            <span style={{ color: "green" }}>
              (+) R$ {formatCurrency(sell || 0)}
            </span>{" "}
          </>
        );
      },
    },
    {
      id: "comission",
      Header: "Comissão",
      accessor: "comission",
      sort: true,
      Cell: ({ row, value }) => {
        const comission = row?.original?.comission;
        return (
          <span style={{ color: "#c0392b" }}>
            R$ {formatCurrency(comission ?? 0)}
          </span>
        );
      },
    },
    {
      id: "manual_credit",
      Header: "Créd. M.",
      accessor: "manual_credit",
      sort: true,
      Cell: ({ row, value }) => {
        return (
          <>
            <Box
              onClick={() =>
                openManualBalance(row?.original?.idUnit, "CREDIT", value)
              }
              cursor="pointer"
            >
              <span style={{ color: "#c0392b" }}>
                (-) R$ {formatCurrency(value || 0)}
              </span>{" "}
            </Box>
          </>
        );
      },
    },
    {
      id: "manual_debit",
      Header: "Déb. M.",
      accessor: "manual_debit",
      sort: true,
      Cell: ({ row, value }) => {
        return (
          <>
            <Box
              onClick={() =>
                openManualBalance(row?.original?.idUnit, "DEBIT", value)
              }
              cursor="pointer"
            >
              <span style={{ color: "green" }}>
                (+) R$ {formatCurrency(value || 0)}
              </span>
            </Box>
          </>
        );
      },
    },
    {
      id: "awards",
      Header: "Prêmios",
      accessor: "awards",
      sort: true,
      Cell: ({ value }) => {
        return (
          <span style={{ color: "#c0392b" }}>
            R$ {formatCurrency(value || 0)}
          </span>
        );
      },
    },
    {
      id: "total",
      Header: "Total",
      accessor: "total",
      sort: true,
      Cell: ({ value }) => {
        return (
          <span style={{ color: value > 0 ? "green" : "#c0392b" }}>
            R$ {formatCurrency(value || 0)}
          </span>
        );
      },
    },
  ];

  const columnsFinanceiro = [
    {
      Header: "#",
      accessor: "label", // accessor is the "key" in the data
    },
    {
      Header: "Valor",
      accessor: "value",
      sort: true,
      Cell: ({ value, row }) => {
        const getColor = () => {
          if (
            row.original.label.includes("Crédito") ||
            row.original.label.includes("Saque (PIX) - Efetivados")
          ) {
            return { color: "#c0392b", symbol: "(-)" };
          }

          if (row.original.label.includes("Saque (PIX) - Retidos")) {
            return { color: "black", symbol: "" };
          }

          return { color: "green", symbol: "(+)" };
        };

        return (
          <span style={{ color: getColor().color }}>
            {getColor().symbol} R$ {formatCurrency(value || 0)}
          </span>
        );
      },
    },
  ];

  const columnsMovimento = [
    {
      Header: "Operação",
      accessor: "operation",
      Cell: ({ value, row }) => {
        const saida = row.original.operation.includes("Saída");
        return (
          <span style={{ color: saida ? "#c0392b" : "green" }}>{value}</span>
        );
      },
    },
    {
      Header: "Provider",
      accessor: "provider",
    },
    {
      Header: "Transações",
      accessor: "transactions",
    },
    {
      Header: "Valor",
      accessor: "value",
      Cell: ({ value, row }) => {
        const saida = row.original.operation.includes("Saída");
        return (
          <span style={{ color: saida ? "#c0392b" : "green" }}>
            {saida ? "(-)" : "(+)"} R$ {formatCurrency(value || 0)}
          </span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              {geralIsLoading ? <LoaderSimple /> : null}

              <Flex direction={["column", "row"]} wrap="wrap" gap={2} my={2}>
                <ChakraButton
                  colorScheme="orange"
                  onClick={() => handleSearch("ontem")}
                >
                  Ontem
                </ChakraButton>
                <ChakraButton
                  colorScheme="yellow"
                  onClick={() => handleSearch("hoje")}
                >
                  Hoje
                </ChakraButton>
                <ChakraButton
                  colorScheme="teal"
                  onClick={() => handleSearch("7d")}
                >
                  7D
                </ChakraButton>
                <ChakraButton
                  colorScheme="blue"
                  onClick={() => handleSearch("30d")}
                >
                  30D
                </ChakraButton>
                <ChakraButton
                  colorScheme="red"
                  onClick={() => handleSearch("mes")}
                >
                  Mês
                </ChakraButton>
              </Flex>
              <Row>
                <Col sm={6}>
                  <Text fontWeight={"bold"} my={2}>
                    Selecione a unidade
                  </Text>
                  <Select
                    placeholder="Todas as unidades"
                    options={options}
                    value={options.find((opt) => opt.value === formUnit)}
                    onChange={(e: any) => setFormUnit(e.value)}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Text fontWeight={"bold"} my={2}>
                    Tipo de unidade
                  </Text>
                  <Select
                    placeholder="Tipo de unidade"
                    options={[
                      {
                        label: "TODOS",
                        value: "",
                      },
                      {
                        label: "CLIENTES",
                        value: "Unidade",
                      },
                      {
                        label: "PROMOTORES",
                        value: "Promotor/Unidade",
                      },
                    ]}
                    value={[
                      {
                        label: "TODOS",
                        value: "",
                      },
                      {
                        label: "CLIENTES",
                        value: "Unidade",
                      },
                      {
                        label: "PROMOTORES",
                        value: "Promotor/Unidade",
                      },
                    ].find((opt) => opt.value === formUnitType)}
                    onChange={(e: any) => setFormUnitType(e.value)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Text fontWeight={"bold"} my={2}>
                    Vendas/Prêmios:
                  </Text>
                  <Checkbox
                    p={2}
                    width="100%"
                    _hover={{
                      backgroundColor: "gray.100",
                    }}
                    size="md"
                    colorScheme="orange"
                    isChecked={games.includes("LOTERIAS")}
                    onChange={() => handleSelectGame("LOTERIAS")}
                  >
                    LOTERIAS
                  </Checkbox>
                  <Checkbox
                    p={2}
                    width="100%"
                    _hover={{
                      backgroundColor: "gray.100",
                    }}
                    size="md"
                    colorScheme="orange"
                    isChecked={games.includes("FAZENDINHA")}
                    onChange={() => handleSelectGame("FAZENDINHA")}
                  >
                    FAZENDINHA
                  </Checkbox>
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Text fontWeight={"bold"} my={2}>
                    Data inicial:
                  </Text>
                  <Input
                    placeholder="Data início"
                    size="md"
                    type="date"
                    value={formStartDate}
                    onChange={(e) => setFormStartDate(e.target.value)}
                  />
                </Col>

                <Col md={3}>
                  <Text fontWeight={"bold"} my={2}>
                    Data Final:
                  </Text>
                  <Input
                    placeholder="Data fim"
                    size="md"
                    type="date"
                    value={formEndDate}
                    onChange={(e) => setFormEndDate(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch("default")}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>

              <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={{ base: "full", md: "lg" }}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Movimentações Manuais</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Flex>
                      <Text my={0} fontWeight={"bold"}>
                        Unidade:
                      </Text>
                      <Spacer />
                      <Text my={0}>{manualData?.unit?.idUnit}</Text>
                    </Flex>

                    <Flex>
                      <Text my={0} fontWeight={"bold"}>
                        Nome:
                      </Text>
                      <Spacer />
                      <Text my={0}>{manualData?.unit?.fullName}</Text>
                    </Flex>

                    <Divider my={0} />
                    <Center>
                      <Text fontWeight={"bold"} my={2}>
                        Operações Manuais
                      </Text>
                    </Center>
                    {manualData?.operations?.length > 0 &&
                      manualData?.operations.map((item) => {
                        return (
                          <Center>
                            <Box
                              my={1}
                              width={"80%"}
                              p={2}
                              borderRadius={12}
                              bgColor={
                                item.transaction === "CREDIT"
                                  ? "red.100"
                                  : "green.100"
                              }
                            >
                              <Flex>
                                <Text my={0} fontWeight={"bold"}>
                                  Transação:
                                </Text>
                                <Spacer />
                                <Text my={0}>
                                  {item.transaction === "CREDIT"
                                    ? "CRÉDITO"
                                    : "DÉBITO"}
                                </Text>
                              </Flex>

                              <Flex>
                                <Text my={0} fontWeight={"bold"}>
                                  Descrição:
                                </Text>
                                <Spacer />
                                <Text my={0}>{item.description}</Text>
                              </Flex>

                              <Flex>
                                <Text my={0} fontWeight={"bold"}>
                                  Valor:
                                </Text>
                                <Spacer />
                                <Text my={0}>
                                  R$ {formatCurrency(item.value || 0)}
                                </Text>
                              </Flex>

                              <Flex>
                                <Text my={0} fontWeight={"bold"}>
                                  Data:
                                </Text>
                                <Spacer />
                                <Text my={0}>
                                  {item?.createdAt &&
                                    format(
                                      new Date(item?.createdAt),
                                      "dd/MM/yyyy HH:mm"
                                    )}
                                </Text>
                              </Flex>
                            </Box>
                          </Center>
                        );
                      })}
                  </ModalBody>
                </ModalContent>
              </Modal>

              {data && data?.length > 0 ? (
                <Table
                  isSearchable={true}
                  columns={columnsGeral}
                  data={data ?? []}
                  pageSize={5}
                  isSortable={true}
                  pagination={true}
                  tableClass="table-hover table-striped"
                  tfoot={
                    <>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <>
                            <span style={{ color: "green" }}>
                              (+) R$ {calcAll("sells", "formated")}
                            </span>{" "}
                          </>
                        </td>

                        <td style={{ color: "#c0392b" }}>
                          R$ {calcAll("comission", "formated")}
                        </td>
                        <td>
                          <>
                            <span style={{ color: "#c0392b" }}>
                              (-) R$ {calcAll("manual_credit", "formated")}
                            </span>{" "}
                          </>
                        </td>

                        <td>
                          <>
                            <span style={{ color: "green" }}>
                              (+) R$ {calcAll("manual_debit", "formated")}
                            </span>
                          </>
                        </td>

                        <td style={{ color: "#c0392b" }}>
                          R$ {calcAll("awards", "formated")}
                        </td>
                        <td
                          style={{
                            color:
                              calcAll("total", "raw") > 0 ? "green" : "#c0392b",
                          }}
                        >
                          R$ {calcAll("total", "formated")}
                        </td>
                      </tr>
                    </>
                  }
                />
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Card>
          <Card.Body>
            {financeIsLoading ? <LoaderSimple /> : null}
            <Row>
              {finance && finance?.resultado ? (
                <>
                  <Col>
                    <h4>Financeiro no Período</h4>

                    <Row>
                      <Col md={12}>
                        <Text mt={2}></Text>
                        <StatisticsWidget
                          variant={
                            finance?.resultado >= 0 ? "success" : "danger"
                          }
                          counterOptions={{
                            prefix: "R$ ",
                            preserveValue: true,
                            decimals: 2,
                            decimal: ",",
                            separator: ".",
                          }}
                          description="Resultado financeiro"
                          stats={finance?.resultado ?? 0}
                          icon="fe-bar-chart-line"
                        />
                        <Text mt={0}>
                          * O resultado financeiro não considera saques
                          pendentes. <br />
                          <b>Resultado:</b>{" "}
                          <span style={{ color: "green" }}>
                            Mandou (PIX) + Débitos (Manual)
                          </span>{" "}
                          <span style={{ color: "red" }}>
                            - Saque (PIX) - Créditos (Manual)
                          </span>{" "}
                        </Text>
                      </Col>
                    </Row>

                    <Table
                      isExportableToExcel={false}
                      isPrintable={false}
                      isExportableToPdf={false}
                      isSearchable={true}
                      columns={columnsFinanceiro}
                      data={finance.financeiro ?? []}
                      pageSize={12}
                      isSortable={true}
                      pagination={true}
                      tableClass="table-hover table-striped"
                    />
                  </Col>
                  <Col>
                    <h4>Movimento Pix no Período</h4>
                    <Table
                      isExportableToExcel={false}
                      isPrintable={false}
                      isExportableToPdf={false}
                      isSearchable={true}
                      columns={columnsMovimento}
                      data={finance.movimento ?? []}
                      pageSize={12}
                      isSortable={true}
                      pagination={true}
                      tableClass="table-hover table-striped"
                    />
                  </Col>
                </>
              ) : null}
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </React.Fragment>
  );
};

export default GeralV2;
