import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Icon,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { FaPlus } from "react-icons/fa";
import { format } from "date-fns";

const colocacoes = [
  "1 PRÊMIO",
  "2 PRÊMIO",
  "3 PRÊMIO",
  "4 PRÊMIO",
  "5 PRÊMIO",
  "6 PRÊMIO",
  "7 PRÊMIO",
  "8 PRÊMIO",
  "9 PRÊMIO",
  "10 PRÊMIO",
  "1/2 PRÊMIO",
  "1/3 PRÊMIO",
  "1/4 PRÊMIO",
  "1/5 PRÊMIO",
  "1/6 PRÊMIO",
  "1/7 PRÊMIO",
  "1/8 PRÊMIO",
  "1/9 PRÊMIO",
  "1/10 PRÊMIO",
  "2/3 PRÊMIO",
  "2/4 PRÊMIO",
  "2/5 PRÊMIO",
  "2/6 PRÊMIO",
  "2/7 PRÊMIO",
  "2/8 PRÊMIO",
  "2/9 PRÊMIO",
  "2/10 PRÊMIO",
  "3/4 PRÊMIO",
  "3/5 PRÊMIO",
  "3/6 PRÊMIO",
  "3/7 PRÊMIO",
  "3/8 PRÊMIO",
  "3/9 PRÊMIO",
  "3/10 PRÊMIO",
  "4/5 PRÊMIO",
  "4/6 PRÊMIO",
  "4/7 PRÊMIO",
  "4/8 PRÊMIO",
  "4/9 PRÊMIO",
  "4/10 PRÊMIO",
  "5/6 PRÊMIO",
  "5/7 PRÊMIO",
  "5/8 PRÊMIO",
  "5/9 PRÊMIO",
  "5/10 PRÊMIO",
  "6/7 PRÊMIO",
  "6/8 PRÊMIO",
  "6/9 PRÊMIO",
  "6/10 PRÊMIO",
  "7/8 PRÊMIO",
  "7/9 PRÊMIO",
  "7/10 PRÊMIO",
  "8/9 PRÊMIO",
  "8/10 PRÊMIO",
  "9/10 PRÊMIO",
];

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Mais Jogadas",
  path: "/base/reports/more-playeds/",
  apiBase: "/bancas/reports/more-playeds",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {new Date(props?.value).toLocaleDateString("pt-BR")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  /**
   * HANDLERS
   */

  const handleClickDelete = async (id: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete(configs.apiBase + id)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <Link
        to="#"
        className="action-icon"
        onClick={() => handleClickDelete(object?.idPromotion)}
      >
        {" "}
        <i className="mdi mdi-delete" style={{ color: "#c0392b" }}></i>
      </Link>
    </React.Fragment>
  );
};

/**
 * COMPONENT
 */
const MorePlayeds = () => {
  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const toast = useToast();

  const dispatch = useDispatch<AppDispatch>();
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formLottery, setFormLottery] = useState<string>("");
  const [colocation, setFormColocation] = useState<string>("");
  const [formModality, setFormModality] = useState<string>("");
  const [lotteries, setLotteries] = useState<any>(null);
  const [modalities, setModalities] = useState<any>(null);

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */

  const handleSearch = async () => {
    const payload = {
      startDate: formStartDate,
      idLottery: formLottery,
      colocation: colocation,
      idModality: formModality,
    };
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar relatório.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));
    try {
      await api.get("/bancas/lotteries", null)?.then((response) => {
        setLotteries(response.data);
      });
      await api.get("/bancas/modalities", null).then((response) => {
        setModalities(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  }, [setData]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  const columns = [
    {
      Header: "Data valendo",
      accessor: "executionAt",
      classes: "fw-semibold",
      Cell: DateColumn,
    },
    {
      Header: "Palpite",
      accessor: "value",
      sort: true,
      classes: "fw-semibold",
    },
    {
      Header: "Posição",
      accessor: "colocation",
      sort: true,
    },
    {
      Header: "Modalidade",
      accessor: "name",
      sort: true,
    },
    {
      Header: "Loteria",
      accessor: "loteria",
      sort: true,
    },
    {
      Header: "Valor",
      accessor: "amount",
      sort: true,
      Cell: ({ value }) => (value ? "R$ " + formatCurrency(value) : ""),
    },
    {
      Header: "Ações",
      accessor: "actions",
      sort: false,
      classes: "table-action",
      Cell: ActionColumn,
    },
  ];

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Text fontWeight={"bold"}>Período:</Text>
                <Col sm={12}>
                  <Col sm={6} className="mb-2">
                    <Input
                      className="form-control"
                      type="date"
                      value={formStartDate}
                      onChange={(e) => setFormStartDate(e.target.value)}
                    />
                  </Col>
                  <Col sm={6} className="mb-2">
                    <Select
                      placeholder="Selecione uma loteria..."
                      onChange={(e) => setFormLottery(e.target.value)}
                      value={formLottery}
                    >
                      {lotteries?.categories.map((category: any) => {
                        return (
                          <optgroup
                            label={category.title}
                            key={category.idCategory}
                          >
                            {lotteries?.lotteries
                              .filter(
                                (lot: any) =>
                                  lot.idCategory === category.idCategory
                              )
                              .map((lottery: any, i: number) => {
                                return (
                                  <option value={lottery.idLottery}>
                                    {lottery.title}
                                  </option>
                                );
                              })}
                          </optgroup>
                        );
                      })}
                    </Select>
                  </Col>

                  <Col sm={6} className="mb-2">
                    <Select
                      placeholder="Todas colocações..."
                      onChange={(e) => setFormColocation(e.target.value)}
                      value={colocation}
                    >
                      {colocacoes.map((coloc: any) => {
                        return <option value={coloc}>{coloc}</option>;
                      })}
                    </Select>
                  </Col>

                  <Col sm={6} className="mb-2">
                    <Select
                      placeholder="Todas modalidades..."
                      onChange={(e) => setFormModality(e.target.value)}
                      value={formModality}
                    >
                      {modalities?.map((mod: any, i: number) => {
                        return (
                          <option value={mod.idModality}>{mod.name}</option>
                        );
                      })}
                    </Select>
                  </Col>
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch()}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>
              <Table
                isSearchable={true}
                columns={columns}
                data={data}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-hover table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MorePlayeds;
