import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Badge,
  Button,
  Box,
  Flex,
  Icon,
  Input,
  Select,
  Spacer,
  Text,
  useToast,
  InputGroup,
  InputRightAddon,
  useDisclosure,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  TableContainer,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  InputLeftAddon,
  Stack,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getActionsPermission } from "../../../helpers/api/auth";
import { FaPlus } from "react-icons/fa";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";
/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Repasses",
  path: "/base/pass/",
  create: "/base/pass/new/",
  apiBase: "/bancas/pass/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {new Date(props?.value).toLocaleString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Repasses" });
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  /**
   * HANDLERS
   */
  const handleClickDelete = async (idPass: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete(configs.apiBase + idPass)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      {actions.canDelete && (
        <Link
          to="#"
          className="action-icon"
          onClick={() => handleClickDelete(object?.idPass)}
        >
          {" "}
          <i className="mdi mdi-delete" style={{ color: "#c0392b" }}></i>
        </Link>
      )}
    </React.Fragment>
  );
};

/**
 * COMPONENT
 */
const Pass = () => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Repasses" });
  const dispatch = useDispatch<AppDispatch>();
  const [limits, setLimits] = useState<any[]>([]);
  const [selectedIdDestiny, setSelectedIdDestiny] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [SelectedIdPass, setSelectedIdPass] = useState<any>(null);

  const openModal = (pass: any) => {
    setSelectedIdPass(pass);
    onOpen();
  };

  const columns = [
    {
      Header: "Loteria",
      accessor: (row) => `${row?.lottery?.code ? row?.lottery?.code : "TODAS"}`,
      sort: true,
    },
    {
      Header: "Destino",
      accessor: (row) => `${row.unit.base.title} (${row.unit.slug})`,
      sort: true,
    },
    {
      Header: "Limites",
      accessor: "idPass",
      sort: true,
      classes: "fw-semibold",
      Cell: ({ row }: { row: any }) => (
        <div onClick={() => openModal(row.original)}>
          <a style={{ cursor: "pointer", color: "#3c8dbc" }}>
            Visualizar #{row.original.idPass}
          </a>
        </div>
      ),
    },

    {
      Header: "Atualizado em",
      accessor: "updatedAt",
      Cell: DateColumn,
      sort: true,
    },
    {
      Header: "Ações",
      accessor: "actions",
      sort: false,
      classes: "table-action",
      Cell: ActionColumn,
    },
  ];

  const fields = [
    {
      name: "MILHAR",
      code: "M",
    },
    {
      name: "CENTENA",
      code: "C",
    },
    {
      name: "DEZENA",
      code: "D",
    },
    {
      name: "UNIDADE",
      code: "U",
    },
    {
      name: "DUQUE DEZ",
      code: "DD",
    },
    {
      name: "TERNO DEZ SECO",
      code: "TDS",
    },
    {
      name: "TERNO DEZ",
      code: "TD",
    },
    {
      name: "GRUPO",
      code: "G",
    },
    {
      name: "DUQUE GP",
      code: "DG",
    },
    {
      name: "TERNO GP",
      code: "TG",
    },
    {
      name: "QUADRA GP",
      code: "QAG",
    },
    {
      name: "QUINA GP",
      code: "QIG",
    },
    {
      name: "SENA GP 10/6",
      code: "SG",
    },
    {
      name: "PALPITÃO",
      code: "PP",
    },
    {
      name: "PASSE VAI",
      code: "PV",
    },
    { name: "PASSE VAI VEM", code: "PVV" },
    {
      name: "QUININHA",
      code: "QUI13",
    },
    {
      name: "SENINHA",
      code: "SEN14",
    },
    {
      name: "LOTINHA",
      code: "LOT16",
    },
  ];

  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const toast = useToast();
  const [dataSelect, setDataSelect] = useState<any>(null);
  const { publish } = useEvent();
  const [lotteries, setLotteries] = useState<any>(null);
  const [formLottery, setFormLottery] = useState<string>("");
  const [modalities, setModalities] = useState([]);

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));

    await api.get(configs.apiBase, null)?.then((response) => {
      setData(response?.data ?? []);
    });
    await api.get("/bancas/lotteries", null)?.then((response) => {
      setLotteries(response.data);
    });
    await api.get("/bancas/modalities", null).then((response) => {
      setModalities(response.data ?? []);
    });
    let newLimits: any[] = [];
    fields.map((field) => {
      newLimits.push({
        code: field.code,
        limit: 0,
      });
    });
    setLimits(newLimits);

    // realiza consulta dos registros
    await api
      .get("/bancas/pass/availables", null)
      .then((response) => {
        const data = response.data ?? [];
        setDataSelect(data);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registros",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  }, [setData]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const postData = async () => {
    if (!selectedIdDestiny) {
      toast({
        title: "Erro ao cadastrar repasse",
        description: "Selecione o destino do repasse.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const removeEmptys = limits.map((limit) => {
      return { ...limit, limit: limit.limit ? limit.limit : "0,00" };
    });

    const data = {
      idUnit: selectedIdDestiny,
      limits: removeEmptys,
      idLottery: formLottery,
    };

    try {
      await api.create(configs.apiBase, data);
      publish("deleteItem", null);
      setLimits([]);

      toast({
        title: "Registro adicionado com sucesso.",
        description: "O registro foi adicionado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);

      toast({
        title: "Erro ao adicionar registro.",
        description: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpdateLimit = (value: string, code: string) => {
    // Check if the value is "0,00"
    if (value === "0,00") {
      // If the value is "0,00", set the value to '0' for all limits
      const newLimits = limits.map((limit) => {
        return {
          ...limit,
          limit: "0",
        };
      });
      setLimits(newLimits);
      return;
    }

    const newLimits = limits.filter((limit) => limit.code !== code);
    newLimits.push({
      code,
      limit: value,
    });
    setLimits(newLimits);
  };

  const getNameOfModality = (idModality: number) => {
    if (idModality === 0) {
      return "Todas";
    }

    const find = modalities.find(
      (modality: any) => modality.idModality === idModality
    )?.code;

    return find || "Não encontrado";
  };

  const getNameOfModalityByCode = (code: string) => {
    if (code === "") {
      return "Modalidade não encontrada";
    }

    const find = modalities.find(
      (codeModality: any) => codeModality.code === code
    )?.name;

    return find || "Não encontrado";
  };

  const handleChangeLottery = (value: number) => {
    const findIfExists = data.find(
      (item: any) => item.idLottery === Number(value)
    );
    const findIfNullExists = data.find((item: any) => !item.idLottery);

    if (findIfExists || findIfNullExists) {
      let lotteryLimits: any[] = [];

      const newLimits = JSON.parse(
        findIfExists?.limits || findIfNullExists?.limits
      );
      newLimits.map((item: any) => {
        const findField = fields.find((field: any) => field.code === item.code);
        if (findField) {
          return lotteryLimits.push({
            code: item.code,
            limit: item.limit,
          });
        }
        return null;
      });

      setLimits(lotteryLimits);
    } else {
      let newLimits: any[] = [];
      fields.map((field) => {
        newLimits.push({
          code: field.code,
          limit: 0,
        });
      });
      setLimits(newLimits);
    }

    setFormLottery(value);
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col sm={4}>
          <Card>
            <Card.Body>
              <h4>Cadastrar Repasses </h4>

              <Select
                name="idUnit"
                defaultValue=""
                onChange={(e) => setSelectedIdDestiny(e.target.value)}
                my={2}
                value={selectedIdDestiny}
              >
                <option value="">Selecione um destino</option>
                {dataSelect &&
                  dataSelect.map((item: any) => (
                    <option key={item.idUnit} value={item.idUnit}>
                      {item.title}
                    </option>
                  ))}
              </Select>
              <Text>
                {JSON.parse(
                  dataSelect?.filter(
                    (value: { idUnit: number }) =>
                      value.idUnit === Number(selectedIdDestiny)
                  )[0]?.modalityComissions || "[]"
                ).map((item: any) => {
                  return (
                    <Badge mr={1} border={"1px solid #444"}>
                      {" "}
                      {getNameOfModality(item?.idModality)} - {item?.value}%
                    </Badge>
                  );
                })}
              </Text>

              <Select
                mb={4}
                placeholder="Todas as loterias"
                onChange={(e) => handleChangeLottery(e.target.value)}
                value={formLottery}
              >
                {lotteries?.categories.map((category: any) => {
                  return (
                    <optgroup label={category.title} key={category.idCategory}>
                      {lotteries?.lotteries
                        .filter(
                          (lot: any) => lot.idCategory === category.idCategory
                        )
                        .map((lottery: any, i: number) => {
                          return (
                            <option value={lottery.idLottery}>
                              {lottery.title}
                            </option>
                          );
                        })}
                    </optgroup>
                  );
                })}
              </Select>

              <Card>
                <Card.Body
                  style={{
                    height: "400px",
                    overflowY: "auto",
                    border: "1px solid #eee",
                    borderRadius: 6,
                  }}
                >
                  <Flex direction={"column"}>
                    {fields.map((field) => (
                      <Flex
                        direction={"row"}
                        align={"center"}
                        justify={"center"}
                        borderBottom="1px solid #ccc"
                        py={2}
                      >
                        <b>{field.name}</b>
                        <Spacer />
                        <Stack>
                          <InputGroup>
                            <InputLeftAddon children="R$" />

                            <Input
                              type={"text"}
                              textAlign={"right"}
                              width={40}
                              value={
                                limits.find(
                                  (limit: any) => limit.code === field.code
                                )?.limit
                                  ? formatCurrency(
                                      Number(
                                        limits.find(
                                          (limit: any) =>
                                            limit.code === field.code
                                        )?.limit
                                      )
                                    )
                                  : "0,00"
                              }
                              onChange={(e) => {
                                let value = parseCurrency(e.target.value);
                                if (!isNaN(value)) {
                                  handleUpdateLimit(String(value), field.code);
                                }
                              }}
                            />
                          </InputGroup>
                        </Stack>
                      </Flex>
                    ))}
                  </Flex>
                </Card.Body>
              </Card>
              <Button
                colorScheme="yellow"
                leftIcon={<Icon as={FaPlus} />}
                onClick={postData}
              >
                Cadastrar repasse
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={8}>
          <Card>
            <Card.Body>
              <h4>Repasses Cadastrados </h4>
              <Table
                isSearchable={true}
                columns={columns}
                data={data}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-hover table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={"700px"}>
            <ModalHeader>
              Visualizar limites de repasse #{SelectedIdPass?.idPass}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box border="1px solid #ccc" p={2} borderRadius={4}>
                <Flex>
                  <Text fontWeight={"bold"}>Destino: </Text>

                  <Text pl={2}>
                    {SelectedIdPass?.unit.base.title.toUpperCase()} (
                    {SelectedIdPass?.unit.slug})
                  </Text>
                  <Spacer></Spacer>
                  <Text fontWeight={"bold"}>Código Loteria: </Text>
                  <Text pl={2}>
                    {SelectedIdPass?.lottery?.code || "TODAS AS DEMAIS"}
                  </Text>
                </Flex>

                <Flex>
                  <Text fontWeight={"bold"}>Comissão: </Text>
                  <Text pl={2}>
                    {JSON.parse(
                      SelectedIdPass?.unit?.modalityComissions || "[]"
                    ).map((item: any) => {
                      return (
                        <Badge mr={1} border={"1px solid #444"}>
                          {" "}
                          {getNameOfModality(item?.idModality)} - {item?.value}%
                        </Badge>
                      );
                    })}
                  </Text>
                </Flex>
              </Box>

              <TableContainer mt={2} border="1px solid #ccc">
                <ChakraTable size="sm" mt={2}>
                  <Thead>
                    <Tr>
                      <Th>Modalidade</Th>
                      <Th isNumeric>Valor</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {SelectedIdPass &&
                      JSON.parse(SelectedIdPass.limits || "[]").map(
                        (item: any) => {
                          return (
                            <Tr key={item.code}>
                              <Td>{getNameOfModalityByCode(item.code)}</Td>
                              <Td isNumeric textAlign={"right"}>
                                R$ {formatCurrency(item.limit)}
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                  </Tbody>
                </ChakraTable>
              </TableContainer>
            </ModalBody>

            <ModalFooter gap={2}>
              <Spacer></Spacer>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Row>
    </React.Fragment>
  );
};

export default Pass;
