import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Badge,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Text,
  useToast,
  Icon,
  Spacer,
  Flex,
  Tooltip,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import {
  CheckIcon,
  ExternalLinkIcon,
  NotAllowedIcon,
  RepeatClockIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";
import {
  FaAccessibleIcon,
  FaHandPointUp,
  FaMoneyBillWaveAlt,
  FaMoneyCheck,
} from "react-icons/fa";
import { getActionsPermission } from "../../../helpers/api/auth";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Saques",
  path: "/base/withdraw/",
  update: "/base/withdraw/update/",
  apiBase: "/bancas/withdraw/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {format(new Date(props?.value), "dd/MM/yyyy HH:mm")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  const status = object?.status;
  const idWithdraw = object?.idWithdraw;
  const external_id = props?.row?.original?.external_id;
  const idProvider = props?.row?.original?.idProvider;

  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Saques" });

  const forceWithdraw = async (idWithdraw: number, provider: string) => {
    dispatch(toggleLoader(true));
    await api
      .create("/bancas/force-withdraw/", { idWithdraw, provider })
      ?.then((response) => {
        publish("deleteItem", null);
        toast({
          title: "Pagamento realizado.",
          description: "O saque foi realizado com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao realizar saque.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  /**
   * HANDLERS
   */

  const downloadComprovante = async () => {
    dispatch(toggleLoader(true));
    await api
      .get("/bancas/comprovante/" + external_id, {
        responseType: "arraybuffer",
      }) // Adicione responseType: 'arraybuffer'
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "comprovante.pdf"); // ou qualquer outro nome de arquivo
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao baixar comprovante",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const updateStatus = async (newStatus: string) => {
    if (newStatus === "pagoexpress" || newStatus === "brc") {
      switch (newStatus) {
        case "pagoexpress":
          return await forceWithdraw(object?.idWithdraw, "PAGOEXPRESS");
        default:
          return await forceWithdraw(object?.idWithdraw, "BRC");
      }
    }

    if (
      window.confirm("Deseja realmente alterar o status deste registro?") ===
      false
    )
      return;
    dispatch(toggleLoader(true));
    await api
      .create(configs.apiBase + idWithdraw, { status: newStatus })
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Saque alterado.",
          description: "O saque foi alterado com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao alterar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const listStatus = [
    {
      name: "PAGA",
      menu: "Pagar manual",
      value: "paid",
      bg: "#00a65a",
      icon: <Icon as={FaHandPointUp} />,
    },
    {
      name: "PAGA",
      menu: "PagoExpress (PIX)",
      value: "pagoexpress",
      bg: "#00a65a",
      color: "#00a65a",
      icon: <Icon as={FaMoneyBillWaveAlt} />,
    },
    {
      name: "PENDENTE",
      menu: "Pendenciar",
      value: "pending",
      bg: "#f39c12",
      icon: <RepeatClockIcon />,
    },
    {
      name: "CANCELADA",
      menu: "Cancelar",
      value: "canceled",
      bg: "red.500",
      color: "red.500",
      icon: <NotAllowedIcon />,
    },
    {
      name: "RECUSADO",
      menu: "Recusar",
      value: "refused",
      bg: "#f39c12",
      icon: <NotAllowedIcon />,
    },
    {
      name: "PROCESSANDO",
      menu: "Processar",
      value: "processing",
      bg: "#444444",
      icon: <RepeatIcon />,
    },
  ];

  const selected = listStatus.find((status) => status.value === object?.status);
  const filterMenu = () => {
    if (status === "paid" || status === "canceled" || status === "processing") {
      return [];
    } else if (status === "pending") {
      return listStatus.filter(
        (status) =>
          status.value !== "pending" &&
          status.value !== "refused" &&
          status.value !== "processing"
      );
    } else if (status === "refused") {
      return listStatus.filter(
        (status) =>
          status.value !== "refused" &&
          status.value !== "processing" &&
          status.value !== "pagoexpress" &&
          status.value !== "pending"
      );
    }
    return listStatus;
  };

  return (
    <Flex gap={2} alignItems={"center"} justifyContent={"center"}>
      <Menu isLazy>
        <MenuButton disabled={filterMenu().length === 0} width={"100%"}>
          <Badge bgColor={selected?.bg} color="white" width={"100%"}>
            {selected?.name}
          </Badge>
        </MenuButton>
        {actions.canWrite && (
          <MenuList my={0} py={0}>
            {/* MenuItems are not rendered unless Menu is open */}
            <MenuGroup title="Realizar ação" my={1} ml={3}>
              {filterMenu().map((item) => (
                <MenuItem
                  my={1}
                  icon={item.icon}
                  onClick={() => updateStatus(item.value)}
                  fontWeight={"bold"}
                  color={item.color ? item.color : "black"}
                >
                  {item.menu}
                </MenuItem>
              ))}
            </MenuGroup>
            {/* {status === "refused" && (
              <MenuGroup title="Falhas" my={1} ml={3}>
                <MenuItem my={1} icon={<RepeatIcon />}>
                  Re-processar
                </MenuItem>
              </MenuGroup>
            )} */}
          </MenuList>
        )}
      </Menu>

      {status === "paid" && idProvider === 4 && (
        <>
          <Spacer />
          <Tooltip label="Download do comprovante">
            <ExternalLinkIcon
              onClick={downloadComprovante}
              cursor={"pointer"}
              boxSize={6}
            />
          </Tooltip>
        </>
      )}
    </Flex>
  );
};

const columns = [
  {
    Header: "ID",
    accessor: "idWithdraw",
    sort: true,
    classes: "fw-semibold",
  },
  {
    Header: "Data criação",
    accessor: "createdAt",
    sort: true,
    Cell: DateColumn,
  },
  {
    Header: "Unidade",
    accessor: "idUnit",
    sort: true,
    classes: "fw-semibold",

    Cell: ({ row, value }: { value: any; row: any }) => {
      const values = row?.original;
      const now = new Date();
      const unitCreatedAt = new Date(values.unitCreatedAt);
      const diff = Math.abs(now.getTime() - unitCreatedAt.getTime());
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

      return (
        <Box>
          {values.idUnit} - {values.unitFullName} <br />
          {format(new Date(values.unitCreatedAt), "dd/MM/yyyy HH:mm")}
          <br />
          {diffDays <= 10 && <Badge colorScheme="red">CADASTRO RECENTE</Badge>}
        </Box>
      );
    },
  },

  {
    Header: "Conta",
    accessor: "name",
    Cell: ({ row }) => {
      const account = row.original;
      return (
        <div>
          {account && account.isManual ? (
            <Badge colorScheme="red" my={1}>
              AVALIAÇÃO
            </Badge>
          ) : null}

          <div>{account ? account.name : ""}</div>
          <div>
            <strong>Método: </strong>
            {account ? account.method : ""}
          </div>
          <div>
            <strong>Tipo: </strong>
            {account ? (
              <>{account.type === "CPF" ? "CPF/CNPJ" : account.type}</>
            ) : (
              ""
            )}
          </div>
          <div>
            <strong>Chave: </strong>
            {account ? account.key : ""}
          </div>
        </div>
      );
    },
  },
  {
    Header: "Valor",
    accessor: "amount",
    sort: true,
    classes: "fw-semibold",
    Cell: ({ value }) => {
      const formattedAmount = formatCurrency(value);
      return <span>R$ {formattedAmount}</span>;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    sort: true,
    classes: "fw-semibold",
    Cell: ActionColumn,
    Footer: <span>a</span>,
    width: 80,
  },
];

/**
 * COMPONENT
 */
const Saques = () => {
  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);
  const toast = useToast();
  const [dataSelect, setDataSelect] = useState<any>(null);
  const [formUnit, setFormUnit] = useState<string>("");
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formStatus, setFormStatus] = useState<string>("");
  const [formManual, setFormManual] = useState<string>("");
  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    dispatch(toggleLoader(true));
    try {
      let dates = {
        startDate: formStartDate,
        endDate: formEndDate,
      };
      let payload = {
        ...dates,
        status: formStatus,
        unit: formUnit,
        isManual: formManual
          ? formManual === "true"
            ? true
            : false
          : undefined,
      };

      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
      await api.get("/bancas/bff/select-data", null).then((response) => {
        setDataSelect(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados de saque.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const preparePayload = (type: string) => {
    let dates = {
      startDate: formStartDate,
      endDate: formEndDate,
    };
    let payload = {
      ...dates,
      status: formStatus,
      unit: formUnit,
      isManual: formManual ? (formManual === "true" ? true : false) : undefined,
    };
    const hoje = format(new Date(), "yyyy-MM-dd");
    const decreaseDate = (qtd: number) => {
      const date = new Date();
      date.setDate(date.getDate() - qtd);
      return format(date, "yyyy-MM-dd");
    };
    if (type === "hoje") {
      payload = {
        ...payload,
        startDate: hoje,
        endDate: hoje,
      };
    } else if (type === "ontem") {
      payload = {
        ...payload,
        startDate: decreaseDate(1),
        endDate: decreaseDate(1),
      };
    } else if (type === "7d") {
      payload = {
        ...payload,
        startDate: decreaseDate(7),
        endDate: hoje,
      };
    } else if (type === "30d") {
      payload = {
        ...payload,
        startDate: decreaseDate(30),
        endDate: hoje,
      };
    } else if (type === "mes") {
      payload = {
        ...payload,
        startDate: hoje.split("-")[0] + "-" + hoje.split("-")[1] + "-01",
        endDate: hoje,
      };
    }
    setFormStartDate(payload.startDate);
    setFormEndDate(payload.endDate);
    return payload;
  };
  const handleSearch = async (type: string) => {
    const payload = preparePayload(type);
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados de saque.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Row>
            <Col md={4}>
              <StatisticsWidget
                variant="success"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                description="Realizados"
                stats={String(
                  sumTotalValue(
                    data.filter((data: any) => data.status === "paid"),
                    "amount"
                  )
                )}
                icon="mdi mdi-currency-usd"
              />
            </Col>
            <Col md={4}>
              <StatisticsWidget
                variant="danger"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                description="Pendentes"
                stats={String(
                  sumTotalValue(
                    data.filter((data: any) => data.status === "pending"),
                    "amount"
                  )
                )}
                icon="fe-bar-chart-line"
              />
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <Flex direction={["column", "row"]} wrap="wrap" gap={2} my={2}>
                <ChakraButton
                  colorScheme="orange"
                  onClick={() => handleSearch("ontem")}
                >
                  Ontem
                </ChakraButton>
                <ChakraButton
                  colorScheme="yellow"
                  onClick={() => handleSearch("hoje")}
                >
                  Hoje
                </ChakraButton>
                <ChakraButton
                  colorScheme="teal"
                  onClick={() => handleSearch("7d")}
                >
                  7D
                </ChakraButton>
                <ChakraButton
                  colorScheme="blue"
                  onClick={() => handleSearch("30d")}
                >
                  30D
                </ChakraButton>
                <ChakraButton
                  colorScheme="red"
                  onClick={() => handleSearch("mes")}
                >
                  Mês
                </ChakraButton>
              </Flex>

              <Row>
                <Text fontWeight={"bold"} my={0} mt={1}>
                  Data inicial:
                </Text>
                <Col md={3}>
                  <Input
                    placeholder="Data início"
                    size="md"
                    type="date"
                    value={formStartDate}
                    onChange={(e) => setFormStartDate(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <Select
                    placeholder="Todas as unidades"
                    value={formUnit}
                    onChange={(e) => setFormUnit(e.target.value)}
                  >
                    <optgroup label={"Regiões"}>
                      {dataSelect?.regions.map((item: any) => (
                        <option value={"R:" + item.idRegion}>
                          {item.name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label={"Cobradores"}>
                      {dataSelect?.collectors.map((item: any) => (
                        <option value={"P:" + item.idUnit}>
                          {item.fullName}
                        </option>
                      ))}
                    </optgroup>
                  </Select>
                </Col>
                <Text fontWeight={"bold"} my={0} mt={1}>
                  Data Final:
                </Text>
                <Col md={3}>
                  <Input
                    placeholder="Data fim"
                    size="md"
                    type="date"
                    value={formEndDate}
                    onChange={(e) => setFormEndDate(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <Select
                    placeholder="Todos status"
                    value={formStatus}
                    onChange={(e) => setFormStatus(e.target.value)}
                  >
                    <option value="pending">Pendentes</option>
                    <option value="paid">Pagos</option>
                    <option value="canceled">Cancelados</option>
                    <option value="refused">Recusados</option>
                    <option value="processing">Processando</option>
                  </Select>
                </Col>
              </Row>
              <Row className="my-2">
                <Col md={3}>
                  <Select
                    placeholder="Todos saques"
                    value={formManual}
                    onChange={(e) => setFormManual(e.target.value)}
                  >
                    <option value="true">Em Avaliação</option>
                    <option value="false">Automaticos</option>
                  </Select>
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch("default")}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>

              <Table
                isCalculate={true}
                calculateInput={"amount"}
                isSearchable={true}
                columns={columns}
                data={data}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-hover table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Saques;
