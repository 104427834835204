import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import DatePicker from "../../../components/Datepicker";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Badge,
  Box,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { format, parseISO } from "date-fns";
import {
  CheckIcon,
  NotAllowedIcon,
  RepeatClockIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Fazendinha",
  path: "/base/farm/pules",
  apiBase: "/bancas/farmpules/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {format(parseISO(props?.value), "dd/MM/yyyy HH:mm:ss")}
    </React.Fragment>
  );
};

/**
 * COMPONENT
 */
const FarmPules = () => {
  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const [lotteries, setLotteries] = useState<any>(null);
  const [dataSelect, setDataSelect] = useState<any>(null);
  const [formUnit, setFormUnit] = useState<string>("");
  const [formLottery, setFormLottery] = useState<string>("");
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formStatus, setFormStatus] = useState<string>("");

  const handleOpenNumbers = (numbers: string[]) => {
    return () => {
      const numbersString = numbers.join(", ");
      toast({
        title: "Números",
        description: numbersString,
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    };
  };

  const columns = [
    {
      id: "idFarmPule",
      Header: "ID",
      accessor: "idFarmPule",
      sort: false,
      classes: "fw-semibold",
      Cell: ({ value }) => {
        const id = value.split("-")[0];
        return id;
      },
    },
    {
      id: "createdAt",
      Header: "Data criação",
      accessor: "createdAt",
      sort: true,
      Cell: DateColumn,
    },
    {
      Header: "ID unidade",
      accessor: "idUnit",
      sort: false,
      classes: "fw-semibold",
    },
    {
      Header: "Unidade",
      accessor: "fullName",
      sort: false,
      classes: "fw-semibold",
    },
    {
      Header: "Loteria",
      accessor: "lotteryCode",
      sort: false,
      classes: "fw-semibold",
    },

    {
      Header: "Números",
      accessor: "numbers",
      sort: false,
      classes: "fw-semibold",
      Cell: ({ row, value }) => {
        const extraction = row?.original?.extraction;

        const numbers = JSON.parse(value || "[]");
        const findExtraction = (number: string) => {
          if (!extraction) return false;
          return extraction === number;
        };

        return (
          <>
            {numbers?.map(
              (val, i) =>
                i <= 2 && (
                  <Badge
                    mr={1}
                    colorScheme={findExtraction(val) ? "green" : "gray"}
                    variant={findExtraction(val) ? "solid" : "outline"}
                  >
                    {val}
                  </Badge>
                )
            )}

            {numbers.length > 3 && (
              <Box
                color="blue"
                cursor={"pointer"}
                onClick={handleOpenNumbers(numbers)}
              >
                ver todos...
              </Box>
            )}
          </>
        );
      },
    },
    {
      id: "price",
      Header: "Valor",
      accessor: "price",
      sort: true,
      classes: "fw-semibold",
      Cell: ({ value }) => {
        const formattedValue = formatCurrency(value);
        return <span>R$ {formattedValue}</span>;
      },
    },
    {
      id: "award",
      Header: "Prêmio",
      accessor: "award",
      sort: true,
      classes: "fw-semibold",
      Cell: ({ value }) => {
        const formattedValue = formatCurrency(value || 0);
        return <span>R$ {formattedValue}</span>;
      },
    },
  ];

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */

  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));
    try {
      await api.get("/bancas/lotteries", null)?.then((response) => {
        setLotteries(response.data);
      });

      await api.get("/bancas/bff/select-data", null).then((response) => {
        setDataSelect(response.data ?? []);
      });

      await handleSearch({});
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  }, [setData]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const handleSearch = async ({
    page,
    field,
    order,
    search,
  }: {
    page?: number;
    field?: string;
    order?: string;
    search?: string;
  }) => {
    const payload = {
      startDate: formStartDate,
      idLottery: formLottery,
      unit: formUnit,
      page,
      field,
      order,
      search,
    };
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados de pules.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const handlePageChange = async ({
    page,
    field,
    order,
    search,
  }: {
    page?: number;
    field?: string;
    order?: string;
    search?: string;
  }) => {
    return await handleSearch({
      page,
      field,
      order,
      search,
    });
  };
  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Text fontWeight={"bold"}>Período:</Text>
                <Col sm={12}>
                  <Col sm={6} className="mb-2">
                    <Input
                      className="form-control"
                      type="date"
                      value={formStartDate}
                      onChange={(e) => setFormStartDate(e.target.value)}
                    />
                  </Col>
                  <Col sm={6} className="mb-2">
                    <Select
                      placeholder="Todas as unidades"
                      value={formUnit}
                      onChange={(e) => setFormUnit(e.target.value)}
                    >
                      <optgroup label={"Regiões"}>
                        {dataSelect?.regions.map((item: any) => (
                          <option value={"R:" + item.idRegion}>
                            {item.name}
                          </option>
                        ))}
                      </optgroup>
                      <optgroup label={"Cobradores"}>
                        {dataSelect?.collectors.map((item: any) => (
                          <option value={"P:" + item.idUnit}>
                            {item.fullName}
                          </option>
                        ))}
                      </optgroup>
                    </Select>
                  </Col>
                  <Col sm={6} className="mb-2">
                    <Select
                      placeholder="Todas as loterias"
                      onChange={(e) => setFormLottery(e.target.value)}
                      value={formLottery}
                    >
                      {lotteries?.categories.map((category: any) => {
                        return (
                          <optgroup
                            label={category.title}
                            key={category.idCategory}
                          >
                            {lotteries?.lotteries
                              .filter(
                                (lot: any) =>
                                  lot.idCategory === category.idCategory
                              )
                              .map((lottery: any, i: number) => {
                                return (
                                  <option value={lottery.idLottery}>
                                    {lottery.title}
                                  </option>
                                );
                              })}
                          </optgroup>
                        );
                      })}
                    </Select>
                  </Col>
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch({ page: 1 })}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>

              <Table
                isSearchable={false} // change
                columns={columns}
                data={data.data || []}
                paginate={data.paginate}
                pageSize={12}
                isSortable={true}
                pagination={true}
                useSortBy={true}
                tableClass="table-hover table-striped"
                onPageChange={handlePageChange}
                tfoot={
                  <>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ color: "black" }}>
                        R$ {formatCurrency(data?.total?.totalValue || 0)}
                      </td>
                      <td style={{ color: "black" }}>
                        R$ {formatCurrency(data?.total?.totalAward || 0)}
                      </td>
                    </tr>
                  </>
                }
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FarmPules;
