import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useRef, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  ButtonGroup,
  Input,
  Select,
  Text,
  useToast,
  Icon,
  Spacer,
  Flex,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Link,
  Textarea,
  CardBody,
  Card as ChakraCard,
  Grid,
  GridItem,
  Heading,
  CardFooter,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  Divider,
  AbsoluteCenter,
  Center,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  InputLeftElement,
  FormErrorMessage,
  InputGroup,
  Switch,
  Image,
} from "@chakra-ui/react";

import { FiFile } from "react-icons/fi";

import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";

import { getActionsPermission } from "../../../helpers/api/auth";
import LoaderSimple from "../../../components/LoaderSimple";
import { css } from "@emotion/react";
import FileUploader from "../../../components/FileUploader";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";

import { useDropzone } from "react-dropzone";

import { FaUpload } from "react-icons/fa";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Configurações",
  path: "/base/configs/",
  apiBase: "/bancas/configs/",
};

/**
 * COMPONENT
 */
const Configs = () => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();

  const [banner, setBanner] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [enable_cashout_tax, setEnableCashoutTax] = useState(false);
  const [enable_double_gift, setEnableDoubleGift] = useState(false);
  const [enable_gift_ten, setEnableGiftTen] = useState(false);
  const [enable_gift_bonus, setEnableGiftBonus] = useState(false);
  const [enable_farm_bonus, setEnableFarmBonus] = useState(false);

  const [minimum_bet_friend_commission, setMinimunBetFriendComission] =
    useState(0);
  const [limit_comission_friend, setLimitComissionFriend] = useState(0);
  const [limit_award_block, setLimitAwardBlock] = useState(0);
  const [limit_withdraw_min, setLimitWithdrawMin] = useState(0);
  const [limit_withdraw_max, setLimitWithdrawMax] = useState(0);
  const [limit_withdraw_daily, setLimitWithdrawDaily] = useState(0);
  const [limit_qtd_withdraw_daily, setLimitQtdWithdrawDaily] = useState(0);
  const [minimal_gift, setMinimalGift] = useState(0);
  const [network_friends, setNetwork_friends] = useState(0);
  const [comission_raffle, setComission_raffle] = useState(0);
  const [comission_farms, setComission_farms] = useState(0);
  const [comission_roulette, setComission_roulette] = useState(0);
  const [disabledLotteries, setDisabledLotteries] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [lotteries, setLotteries] = useState<any[]>([]);
  const [title, setTitle] = useState("");
  const [link_support, setLinkSupport] = useState("");
  const [action, setAction] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFilePreview, setSelectedFilePreview] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [fileName, setFileName] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const fileType = file.type;
      const validTypes = ["image/jpeg", "image/png", "image/bmp", "image/webp"];
      if (validTypes.includes(fileType)) {
        const reader = new FileReader();

        reader.onload = async () => {
          const resizedImage = await resizeImage(file, 1080, 1920);
          const preview = URL.createObjectURL(resizedImage);

          setSelectedFile(resizedImage);
          setSelectedFilePreview(preview);
          setFileName(file.name);
        };

        reader.readAsDataURL(file);
      } else {
        alert(
          "Por favor, selecione um arquivo de imagem válido (jpg, jpeg, png, bmp, webp)."
        );
      }
    } else {
      setSelectedFile(null);
      setSelectedFilePreview("");
      setFileName("");
    }
  }, []);

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();
  const { publish } = useEvent();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    setIsLoading(true);
    try {
      await api.get(configs.apiBase, null).then((response) => {
        setBanner(response.data.banner ?? null);
        setComission_farms(response.data.comission_farms ?? 0);
        setComission_raffle(response.data.comission_raffle ?? 0);
        setLinkSupport(response.data.link_support ?? "");
        setNetwork_friends(response.data.network_friends ?? 0);
        setComission_roulette(response.data.comission_roulette ?? 0);
        setEnableCashoutTax(response.data.enable_cashout_tax ?? 0);
        setEnableDoubleGift(response.data.enable_double_gift ?? 0);
        setEnableGiftTen(response.data.enable_gift_ten ?? 0);
        setEnableGiftBonus(response.data.enable_gift_bonus ?? 0);
        setEnableFarmBonus(response.data.enable_farm_bonus ?? 0);
        setMinimalGift(response.data.minimal_gift ?? 0);

        setLimitComissionFriend(response.data.limit_comission_friend ?? 0);
        setMinimunBetFriendComission(
          response.data.minimum_bet_friend_commission ?? 0
        );

        setLimitAwardBlock(response.data.limit_award_block ?? 0);
        setLimitWithdrawMin(response.data.limit_withdraw_min ?? 0);
        setLimitWithdrawMax(response.data.limit_withdraw_max ?? 0);
        setLimitWithdrawDaily(response.data.limit_withdraw_daily ?? 0);
        setLimitQtdWithdrawDaily(response.data.limit_qtd_withdraw_daily ?? 0);
      });

      await api.get("/bancas/lotteries", null)?.then((response) => {
        setCategories(response?.data?.categories ?? []);
        setLotteries(response?.data?.lotteries ?? []);
      });

      await api.get("/bancas/disabled-lotteries", null)?.then((response) => {
        setDisabledLotteries(response?.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar configurações.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  const handlePageLoading = useCallback(async () => {
    setTitle("");
    setAction("");
    setSelectedFile(null);
    setFileName("");

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  const handleSaveFarm = async () => {
    const payload = {
      comission_raffle,
      comission_farms,
      comission_roulette,
      network_friends,
      enable_cashout_tax: enable_cashout_tax ? 1 : 0,
      enable_double_gift: enable_double_gift ? 1 : 0,
      enable_gift_bonus: enable_gift_bonus ? 1 : 0,
      enable_gift_ten: enable_gift_ten ? 1 : 0,
      minimal_gift,
      disabledLotteries: JSON.stringify(disabledLotteries),
      limit_comission_friend,
      minimum_bet_friend_commission,
      limit_award_block,
      limit_withdraw_min,
      limit_withdraw_max,
      limit_withdraw_daily,
      limit_qtd_withdraw_daily,
      link_support,
      enable_farm_bonus: enable_farm_bonus ? 1 : 0,
    };

    if (
      window.confirm(
        "Você tem certeza de que todos os dados estão corretos? Se tens dúvida, clique em cancelar e verifique novamente."
      ) === false
    )
      return;

    dispatch(toggleLoader(true));

    try {
      await api.create("/bancas/configs", payload).then((response) => {
        toast({
          title: "Configuração salva com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
    } catch (e) {
      toast({
        title: "Erro ao salvar configuração.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    publish("deleteItem", null);
    dispatch(toggleLoader(false));
  };

  const handleRemoveBanner = async () => {
    dispatch(toggleLoader(true));

    try {
      await api.create("/bancas/configs/remove-banner", {}).then((response) => {
        toast({
          title: "Banner removido com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
    } catch (e) {
      toast({
        title: "Erro ao remover banner.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    publish("deleteItem", null);
    dispatch(toggleLoader(false));
  };

  const handleSaveConfigsBanner = async () => {
    if (!title) {
      toast({
        title: "Preencha o título do banner.",
        description: "",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!selectedFile) {
      toast({
        title: "Selecione o banner.",
        description: "",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    dispatch(toggleLoader(true));

    const payload = {
      title,
      banner: selectedFile,
      link: action,
    };

    try {
      dispatch(toggleLoader(true));
      await api
        .createWithFile("/bancas/configs/banner", payload)
        .then((response) => {
          toast({
            title: "Banner salvo com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (e) {
      toast({
        title: "Erro ao salvar banner.",
        description: e?.message || "",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
    publish("deleteItem", null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSaveConfigs = async () => {
    await handleSaveFarm();
  };

  const handleToggleLottery = (id: number) => {
    let oldStatus;
    const find = disabledLotteries.find((lot) => lot.idLottery === id);

    if (find) {
      oldStatus = find.value;
    } else {
      oldStatus = true;
    }

    const newStatus = !oldStatus;

    const payload = {
      idLottery: id,
      value: newStatus,
    };

    const removed = disabledLotteries.filter((lot) => lot.idLottery !== id);

    setDisabledLotteries([...removed, payload]);
  };

  const findIfIsChecked = (id: number) => {
    const find = disabledLotteries.find((lot) => lot.idLottery === id);

    if (find) {
      return find.value;
    }

    return true;
  };

  const resizeImage = (file, width, height) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = document.createElement("img");

        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = (width * img.height) / img.width;
          const ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, (width * img.height) / img.width);

          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error("Falha ao criar o Blob da imagem."));
                return;
              }
              const resizedFile = new File([blob], "resized_image.jpg", {
                type: "image/jpeg",
                lastModified: Date.now(),
              });

              resolve(resizedFile);
            },
            "image/jpeg",
            0.7
          );
        };

        img.onerror = (error) => {
          console.log(error);
          reject(error);
        };

        img.src = event.target.result;
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              {isLoading ? <LoaderSimple /> : null}

              <Row>
                <Tabs variant="enclosed" size="md">
                  <TabList
                    overflowX="auto"
                    style={{
                      scrollbarWidth: "none",
                      WebkitOverflowScrolling: "touch",
                    }}
                  >
                    <Tab>Geral</Tab>
                    <Tab>Comissões</Tab>
                    <Tab>Bônus</Tab>
                    <Tab>Limites & Taxas</Tab>
                    <Tab>Milhar Brinde</Tab>
                    <Tab>Loterias</Tab>
                    <Tab>Banner</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Row>
                        <Col md="6">
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Configurações gerais:
                          </Text>

                          <FormControl mb={2}>
                            <FormLabel>Link para suporte:</FormLabel>
                            <Input
                              value={link_support}
                              onChange={(e) => setLinkSupport(e.target.value)}
                            />
                          </FormControl>

                          <Button
                            mt={4}
                            colorScheme="teal"
                            type="submit"
                            onClick={handleSaveConfigs}
                          >
                            Salvar configurações
                          </Button>
                          {/* Banners */}
                        </Col>
                      </Row>
                    </TabPanel>

                    {/* Comissiões */}
                    <TabPanel>
                      <Row>
                        <Col md={6}>
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Comissões:
                          </Text>

                          <FormControl mb={2}>
                            <FormLabel>Fazendinha: (%)</FormLabel>
                            <NumberInput
                              max={7}
                              min={0}
                              value={comission_farms}
                              onChange={(value) =>
                                setComission_farms(Number(value))
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>

                          {/* <FormControl mb={2}>
                            <FormLabel>Rifinha: (%)</FormLabel>
                            <NumberInput
                              max={7}
                              min={0}
                              value={comission_raffle}
                              onChange={(value) =>
                                setComission_raffle(Number(value))
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl> */}

                          <FormControl mb={2}>
                            <FormLabel>
                              Rede de Amigos: (% - TODOS JOGOS)
                            </FormLabel>
                            <NumberInput
                              max={50}
                              min={0}
                              value={network_friends}
                              onChange={(value) =>
                                setNetwork_friends(Number(value))
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>

                          <Button
                            mt={4}
                            colorScheme="teal"
                            type="submit"
                            onClick={handleSaveConfigs}
                          >
                            Salvar configurações
                          </Button>
                        </Col>
                      </Row>
                    </TabPanel>

                    {/* Bônus */}
                    <TabPanel>
                      <Row>
                        <Col md={6}>
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Bônus:
                          </Text>

                          <FormControl mb={2}>
                            <FormLabel>Usar bônus na fazendinha?</FormLabel>
                            <Switch
                              id="enable_farm_bonus"
                              size="lg"
                              isChecked={enable_farm_bonus}
                              onChange={() =>
                                setEnableFarmBonus(!enable_farm_bonus)
                              }
                            />
                          </FormControl>

                          <Button
                            mt={4}
                            colorScheme="teal"
                            type="submit"
                            onClick={handleSaveConfigs}
                          >
                            Salvar configurações
                          </Button>
                        </Col>
                      </Row>
                    </TabPanel>

                    {/* Limites */}
                    <TabPanel>
                      <Row>
                        <Col md={6}>
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Limites de saque por unidade:
                          </Text>

                          <FormControl mb={2}>
                            <FormLabel>Cobrar taxa para saque (1%):</FormLabel>
                            <Switch
                              id="enable_cashout_tax"
                              size="lg"
                              isChecked={enable_cashout_tax}
                              onChange={() =>
                                setEnableCashoutTax(!enable_cashout_tax)
                              }
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>Solicitação mínima:</FormLabel>
                            <Input
                              type="text"
                              placeholder="R$ 0,00"
                              value={formatCurrency(limit_withdraw_min || 0)}
                              onChange={(e: any) => {
                                setLimitWithdrawMin(
                                  parseCurrency(e.target.value)
                                );
                              }}
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>Solicitação máxima:</FormLabel>
                            <Input
                              type="text"
                              placeholder="R$ 0,00"
                              value={formatCurrency(limit_withdraw_max || 0)}
                              onChange={(e: any) => {
                                setLimitWithdrawMax(
                                  parseCurrency(e.target.value)
                                );
                              }}
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>Saque diário máximo:</FormLabel>
                            <Input
                              type="text"
                              placeholder="R$ 0,00"
                              value={formatCurrency(limit_withdraw_daily || 0)}
                              onChange={(e: any) => {
                                setLimitWithdrawDaily(
                                  parseCurrency(e.target.value)
                                );
                              }}
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>
                              Quantidade de saque diário máximo:
                            </FormLabel>

                            <NumberInput
                              max={100}
                              min={1}
                              value={limit_qtd_withdraw_daily}
                              onChange={(value) =>
                                setLimitQtdWithdrawDaily(Number(value))
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>

                          <Button
                            mt={4}
                            colorScheme="teal"
                            type="submit"
                            onClick={handleSaveConfigs}
                          >
                            Salvar configurações
                          </Button>
                        </Col>

                        <Col md={6}>
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Segurança:
                          </Text>

                          <FormControl mb={2}>
                            <FormLabel>
                              Valor máx. diário para comissão de amigo:
                            </FormLabel>
                            <Input
                              type="text"
                              placeholder="R$ 0,00"
                              value={formatCurrency(limit_comission_friend)}
                              onChange={(e: any) => {
                                setLimitComissionFriend(
                                  parseCurrency(e.target.value)
                                );
                              }}
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>
                              Valor mín. de aposta para comissionar amigos:
                            </FormLabel>
                            <Input
                              type="text"
                              placeholder="R$ 0,00"
                              value={formatCurrency(
                                minimum_bet_friend_commission
                              )}
                              onChange={(e: any) => {
                                setMinimunBetFriendComission(
                                  parseCurrency(e.target.value)
                                );
                              }}
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>
                              Desabilitar saque da unidade após prêmio:
                            </FormLabel>
                            <Input
                              type="text"
                              placeholder="R$ 0,00"
                              value={formatCurrency(limit_award_block)}
                              onChange={(e: any) => {
                                setLimitAwardBlock(
                                  parseCurrency(e.target.value)
                                );
                              }}
                            />
                          </FormControl>

                          <Button
                            mt={4}
                            colorScheme="teal"
                            type="submit"
                            onClick={handleSaveConfigs}
                          >
                            Salvar configurações
                          </Button>
                        </Col>
                      </Row>
                      {/* Configurações */}
                    </TabPanel>
                    {/* Milhar Brinde */}
                    <TabPanel>
                      <Row>
                        <Col md={6}>
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Opções da Milhar Brinde:
                          </Text>

                          <FormControl mb={2}>
                            <FormLabel>Valor mínimo da pule:</FormLabel>
                            <NumberInput
                              max={50}
                              min={5}
                              value={minimal_gift}
                              onChange={(value) =>
                                setMinimalGift(Number(value))
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>
                              Tripla (Promotor, Cliente e Amigo):
                            </FormLabel>
                            <Switch
                              id="enable_double_gift"
                              size="lg"
                              isChecked={enable_double_gift}
                              onChange={() =>
                                setEnableDoubleGift(!enable_double_gift)
                              }
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>1º ao 10º?</FormLabel>
                            <Switch
                              id="enable_gift_ten"
                              size="lg"
                              isChecked={enable_gift_ten}
                              onChange={() =>
                                setEnableGiftTen(!enable_gift_ten)
                              }
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>Prêmio em Bônus?</FormLabel>
                            <Switch
                              id="enable_gift_bonus"
                              size="lg"
                              isChecked={enable_gift_bonus}
                              onChange={() =>
                                setEnableGiftBonus(!enable_gift_bonus)
                              }
                            />
                          </FormControl>

                          <Button
                            mt={4}
                            colorScheme="teal"
                            type="submit"
                            onClick={handleSaveConfigs}
                          >
                            Salvar configurações
                          </Button>
                        </Col>
                      </Row>
                      {/* Configurações */}
                    </TabPanel>
                    {/* Loterias */}
                    <TabPanel>
                      <Col md={12}>
                        <Box width={"100%"}>
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Loterias:
                          </Text>

                          <Box
                            bgColor="#f9f9f9"
                            border="1px solid #444"
                            borderRadius="12"
                          >
                            {categories.map((category: any) => {
                              return (
                                <div key={category.idCategory}>
                                  <Box position="relative" padding="0">
                                    <Divider />
                                    <AbsoluteCenter
                                      bg="white"
                                      px="4"
                                      fontWeight={"bold"}
                                    >
                                      {category.title}
                                    </AbsoluteCenter>
                                  </Box>
                                  <Center>
                                    <TableContainer width={"100%"}>
                                      <Table size="sm">
                                        <Thead>
                                          <Tr>
                                            <Th>Loteria</Th>
                                            <Th w={50}>Código</Th>
                                          </Tr>
                                        </Thead>
                                        <Tbody>
                                          {lotteries
                                            .filter(
                                              (lot: any) =>
                                                lot.idCategory ===
                                                category.idCategory
                                            )
                                            .map((lottery: any, i: number) => {
                                              return (
                                                <Tr key={"lottery" + i}>
                                                  <Td py={0}>
                                                    <Checkbox
                                                      p={2}
                                                      width="100%"
                                                      _hover={{
                                                        backgroundColor:
                                                          "gray.100",
                                                      }}
                                                      size="md"
                                                      colorScheme="orange"
                                                      isChecked={findIfIsChecked(
                                                        lottery.idLottery
                                                      )}
                                                      onChange={() =>
                                                        handleToggleLottery(
                                                          lottery.idLottery
                                                        )
                                                      }
                                                    >
                                                      {lottery.title}
                                                    </Checkbox>
                                                  </Td>
                                                  <Td isNumeric>
                                                    {lottery.code}
                                                  </Td>
                                                </Tr>
                                              );
                                            })}
                                        </Tbody>
                                      </Table>
                                    </TableContainer>
                                  </Center>
                                </div>
                              );
                            })}
                          </Box>

                          <Center>
                            <Button
                              mt={4}
                              colorScheme="teal"
                              type="submit"
                              onClick={handleSaveConfigs}
                            >
                              Salvar configurações
                            </Button>
                          </Center>
                        </Box>
                      </Col>
                      {/* Loterias */}
                    </TabPanel>
                    {/* Banners */}
                    <TabPanel>
                      <Row>
                        <Col md="6">
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Banner de entrada:
                          </Text>

                          <FormControl mb={2}>
                            <FormLabel>Titulo do banner:</FormLabel>
                            <Input
                              maxLength={30}
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>Banner:</FormLabel>

                            <Box
                              {...getRootProps()}
                              p={6}
                              border="2px dashed"
                              borderColor={
                                isDragActive ? "teal.500" : "gray.300"
                              }
                              borderRadius="md"
                              textAlign="center"
                              cursor="pointer"
                              _hover={{ borderColor: "teal.500" }}
                            >
                              <input {...getInputProps()} />
                              {isDragActive ? (
                                <Text color="teal.500" fontWeight="bold">
                                  Solte o arquivo aqui...
                                </Text>
                              ) : fileName ? (
                                <Text color="green.500" fontWeight="bold">
                                  {fileName}
                                </Text>
                              ) : (
                                <Box>
                                  <Icon color="gray.500" as={FaUpload} />
                                  <Text color="gray.500">
                                    Arraste e solte um arquivo aqui, ou clique
                                    para selecionar
                                  </Text>
                                </Box>
                              )}
                            </Box>
                            {selectedFile && (
                              <img
                                src={selectedFilePreview}
                                width={300}
                                height={600}
                                className="banner rounded mt-2"
                                alt="selectedFile"
                              />
                            )}
                          </FormControl>
                          <FormControl mb={2}>
                            <FormLabel>Ação:</FormLabel>
                            <Select
                              value={action}
                              onChange={(e) => setAction(e.target.value)}
                            >
                              <option value="">SEM AÇÃO</option>
                              <option value="promotor">
                                Falar com Promotor
                              </option>
                              <option value="amigos">Rede de Amigos</option>
                              <option value="fazendinha">Fazendinha</option>
                              <option value="roletinha">Roletinha</option>
                              <option value="jogos">Jogo do bicho</option>
                              <option value="raspadinha">Raspadinha</option>
                              <option value="recarga-pix">Recargas</option>
                              <option value="saque-listar">Saques</option>
                              <option value="relatorios">Relatórios</option>
                            </Select>
                          </FormControl>

                          <Button
                            mt={4}
                            colorScheme="teal"
                            type="submit"
                            onClick={handleSaveConfigsBanner}
                          >
                            Salvar configurações
                          </Button>
                          {/* Banners */}
                        </Col>
                        <Col md="6">
                          {banner && banner?.title && (
                            <>
                              <hr />
                              <Text fontWeight={"bold"} fontSize={"lg"}>
                                Banner atual:
                              </Text>

                              <Box color="gray.500" mt={2}>
                                <Text fontWeight={"bold"} fontSize={"md"}>
                                  TÍTULO: {banner?.title}
                                </Text>
                                <Text
                                  fontWeight={"bold"}
                                  fontSize={"md"}
                                  textTransform={"uppercase"}
                                >
                                  AÇÃO:{" "}
                                  {banner?.link ? banner.link : "SEM AÇÃO"}
                                </Text>
                                <Image
                                  src={banner?.banner}
                                  alt="Preview"
                                  w={"100%"}
                                  className="banner rounded"
                                />
                              </Box>

                              <Button
                                mt={4}
                                colorScheme="red"
                                type="submit"
                                onClick={handleRemoveBanner}
                              >
                                Remover banner atual
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Configs;
