import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import { Icon, useToast, Button } from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { FaPlus } from "react-icons/fa";
/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Usuários Banca",
  path: "/base/users",
  create: "/base/users/new/",
  update: "/base/users/update/",
  apiBase: "/bancas/users/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {new Date(props?.value).toLocaleDateString("pt-BR")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();

  /**
   * HANDLERS
   */
  const handleClickDelete = (id: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    api
      .delete(configs.apiBase + id)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <Link to={configs.update + object?.idUser} className="action-icon">
        {" "}
        <i
          className="mdi mdi-square-edit-outline"
          style={{ color: "#2980b9" }}
        ></i>
      </Link>

      <Link
        to="#"
        className="action-icon"
        onClick={() => handleClickDelete(object?.idUser)}
      >
        {" "}
        <i className="mdi mdi-delete" style={{ color: "#c0392b" }}></i>
      </Link>
    </React.Fragment>
  );
};

const columns = [
  {
    Header: "ID",
    accessor: "idUser",
    sort: true,
    classes: "fw-semibold",
  },
  {
    Header: "Nome",
    accessor: "fullName",
    sort: false,
  },
  {
    Header: "Usuário",
    accessor: "username",
    sort: false,
  },
  {
    Header: "Permissão",
    accessor: "permission",
    sort: false,
  },
  {
    Header: "Data criação",
    accessor: "createdAt",
    sort: false,
    Cell: DateColumn,
  },
  {
    Header: "Ações",
    accessor: "actions",
    sort: false,
    classes: "table-action",
    Cell: ActionColumn,
  },
];

/**
 * COMPONENT
 */
const UserBase = () => {
  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const toast = useToast();

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoading = useCallback(async () => {
    api
      .get(configs.apiBase, null)
      ?.then((response) => {
        setUsers(response?.data ?? []);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registros",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [setUsers]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */
  const handleAddUser = useCallback(() => {
    navigate(configs.create);
  }, [navigate]);

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: configs.title, path: configs.path, active: true },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="mb-2">
                <Col sm={4}>
                  <Button
                    mr={2}
                    colorScheme="yellow"
                    leftIcon={<Icon as={FaPlus} />}
                    onClick={handleAddUser}
                  >
                    Adicionar Novo
                  </Button>
                </Col>
              </Row>

              <Table
                isSearchable={true}
                columns={columns}
                data={users}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserBase;
