import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import LoaderSimple from "../../../components/LoaderSimple";
import UserSelect from "../../../components/UserSelect";
import SectionSelect from "../../../components/SectionSelect";
import OperationSelect from "../../../components/OperationSelect";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Box,
  Input,
  Text,
  useToast,
  Flex,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  IconButton,
  useDisclosure,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table as ChakraTable,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

/**
 * HELPERS
 */

interface LogData {
  username: string;
  section: string;
  operation: string;
  description: string;
  registeredAt: string;
  identifier?: {}[];
  changes?: {};
  detailsBefore?: {};
  detailsAfter?: {};
}

interface ApiResponse {
  data: {
    content: LogData[];
    paginate: {
      lastPage: number;
      page: number;
      perPage: number;
      total: number;
    };
  };
}

const api = new APICore();
const configs = {
  title: "Auditoria",
  path: "/base/logs",
  apiBase: "/bancas/logs",
  apiEnums: "/bancas/logs/enums",
};

/**
 * COMPONENT
 */
const Logs = () => {
  const { usrData } = useSelector((state: RootState) => ({
    usrData: state?.Auth?.user?.userData,
  }));

  /**
   * HOOKS
   */
  const [data, setData] = useState<LogData[]>([]);
  const [enums, setEnums] = useState<any>(null);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formUser, setFormUser] = useState<string>("");
  const [formSection, setFormSection] = useState<string>("");
  const [formOperation, setFormOperation] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedLog, setSelectedLog] = useState<LogData | null>(null);
  const [paginate, setPaginate] = useState<any>({});

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const fetchEnums = async () => {
    try {
      const response = await api.get(configs.apiEnums, {});
      setEnums(response.data);
    } catch (e) {
      toast({
        title: "Erro ao buscar enums.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchData = async (page: number = 1) => {
    setIsLoading(true);
    try {
      let payload = {
        section: formSection,
        operation: formOperation,
        startDate: formStartDate,
        endDate: formEndDate,
        idUser: formUser,
        page: Math.max(1, page),
      };

      const response: ApiResponse = await api.create(configs.apiBase, payload);
      setData(response.data.content ?? []);
      setPaginate({
        ...response.data.paginate,
        page: response.data.paginate?.page + 1,
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageLoading = useCallback(async () => {
    await handleSearch("default");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();
    fetchEnums();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const renderValue = (value) => {
    if (typeof value === "object" && value !== null) {
      if (Array.isArray(value)) {
        return value.map((item, index) => (
          <div key={index}>{JSON.stringify(item, null, 2)}</div>
        ));
      } else {
        return Object.keys(value).map((key, index) => (
          <div key={index}>
            {key} - {value[key]}
          </div>
        ));
      }
    }
    return JSON.stringify(value, null, 2);
  };

  const preparePayload = (type: string) => {
    let dates = {
      startDate: formStartDate,
      endDate: formEndDate,
    };

    let payload = {
      section: formSection,
      operation: formOperation,
      startDate: formStartDate,
      endDate: formEndDate,
      idUser: formUser,
      page: 1,
    };

    const hoje = format(new Date(), "yyyy-MM-dd");
    const decreaseDate = (qtd: number) => {
      const date = new Date();
      date.setDate(date.getDate() - qtd);
      return format(date, "yyyy-MM-dd");
    };

    if (type === "hoje") {
      payload = {
        ...payload,
        startDate: hoje,
        endDate: hoje,
      };
    } else if (type === "ontem") {
      payload = {
        ...payload,
        startDate: decreaseDate(1),
        endDate: decreaseDate(1),
      };
    } else if (type === "7d") {
      payload = {
        ...payload,
        startDate: decreaseDate(7),
        endDate: hoje,
      };
    } else if (type === "30d") {
      payload = {
        ...payload,
        startDate: decreaseDate(30),
        endDate: hoje,
      };
    } else if (type === "mes") {
      payload = {
        ...payload,
        startDate: hoje.split("-")[0] + "-" + hoje.split("-")[1] + "-01",
        endDate: hoje,
      };
    }

    setFormStartDate(payload.startDate);
    setFormEndDate(payload.endDate);

    return payload;
  };

  const handleSearch = async (type: string) => {
    const payload = preparePayload(type);
    setIsLoading(true);

    try {
      const response: ApiResponse = await api.create(configs.apiBase, payload);
      setData(response.data.content ?? []);
      setPaginate({
        ...response.data.paginate,
        page: response.data.paginate?.page + 1,
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewDetails = (log: LogData) => {
    setSelectedLog(log);
    onOpen();
  };

  const handlePageChange = (params: {
    page: number;
    field?: string;
    order?: string;
    search?: string;
  }): void => {
    setIsLoading(true);
    try {
      let payload = {
        section: formSection,
        operation: formOperation,
        startDate: formStartDate,
        endDate: formEndDate,
        idUser: formUser,
        page: Math.max(1, params.page),
      };

      api
        .create(configs.apiBase, payload)
        .then((response: ApiResponse) => {
          setData(response.data.content ?? []);
          setPaginate({
            ...response.data.paginate,
            page: response.data.paginate?.page + 1,
          });
          setIsLoading(false);
        })
        .catch((e) => {
          toast({
            title: "Erro ao buscar registros.",
            description: e,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setIsLoading(false);
        });
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleUserChange = (option: any) => {
    setFormUser(option ? option.value : "");
  };

  const handleSectionChange = (option: any) => {
    setFormSection(option ? option.value : "");
  };

  const handleOperationChange = (option: any) => {
    setFormOperation(option ? option.value : "");
  };

  const isAdmin = usrData?.username.includes("sistema");

  const columns = [
    {
      Header: "Data",
      accessor: "registeredAt",
      Cell: ({ value }: { value: string }) => {
        return <Text>{value}</Text>;
      },
    },
    {
      Header: "Usuário",
      accessor: "username",
    },
    {
      Header: "Seção",
      accessor: "section",
    },
    {
      Header: "Operação",
      accessor: "operation",
      Cell: ({ value }: { value: string }) => {
        const getColor = (operation: string) => {
          switch (operation.toUpperCase()) {
            case "CRIAR":
              return "green";
            case "EXCLUIR":
              return "red";
            case "ALTERAR":
              return "blue";
            case "BUSCAR":
              return "orange";
            default:
              return "black";
          }
        };

        return <Text color={getColor(value)}>{value}</Text>;
      },
    },
    {
      Header: "Ações",
      accessor: "actions",
      Cell: ({ row }: any) => (
        <IconButton
          aria-label="Ver detalhes"
          icon={<i className="fas fa-eye"></i>}
          size="sm"
          colorScheme="blue"
          onClick={() => handleViewDetails(row.original)}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              {isLoading ? <LoaderSimple /> : null}

              <Flex direction={["column", "row"]} wrap="wrap" gap={2} my={2}>
                <ChakraButton
                  colorScheme="orange"
                  onClick={() => handleSearch("ontem")}
                >
                  Ontem
                </ChakraButton>
                <ChakraButton
                  colorScheme="yellow"
                  onClick={() => handleSearch("hoje")}
                >
                  Hoje
                </ChakraButton>
                <ChakraButton
                  colorScheme="teal"
                  onClick={() => handleSearch("7d")}
                >
                  7D
                </ChakraButton>
                <ChakraButton
                  colorScheme="blue"
                  onClick={() => handleSearch("30d")}
                >
                  30D
                </ChakraButton>
                <ChakraButton
                  colorScheme="red"
                  onClick={() => handleSearch("mes")}
                >
                  Mês
                </ChakraButton>
              </Flex>

              <Row>
                <Col sm={12}>
                  <Text fontWeight={"bold"} my={2}>
                    Usuário
                  </Text>
                  <Flex direction={"column"} width={"100%"}>
                    <UserSelect
                      data={enums?.users || []}
                      onChange={handleUserChange}
                      placeholder="Selecione ou pesquise um usuário"
                    />
                  </Flex>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Text fontWeight={"bold"} my={2}>
                    Seção
                  </Text>
                  <Flex direction={"column"} width={"100%"}>
                    <SectionSelect
                      data={enums?.sections || []}
                      onChange={handleSectionChange}
                      placeholder="Selecione ou pesquise uma seção"
                    />
                  </Flex>
                </Col>

                <Col sm={6}>
                  <Text fontWeight={"bold"} my={2}>
                    Operação
                  </Text>
                  <Flex direction={"column"} width={"100%"}>
                    <OperationSelect
                      data={enums?.operations || []}
                      onChange={handleOperationChange}
                      placeholder="Selecione ou pesquise uma operação"
                    />
                  </Flex>
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Text fontWeight={"bold"} my={2}>
                    Data inicial:
                  </Text>
                  <Input
                    placeholder="Data início"
                    size="md"
                    type="date"
                    value={formStartDate}
                    onChange={(e) => setFormStartDate(e.target.value)}
                  />
                </Col>

                <Col md={3}>
                  <Text fontWeight={"bold"} my={2}>
                    Data Final:
                  </Text>
                  <Input
                    placeholder="Data fim"
                    size="md"
                    type="date"
                    value={formEndDate}
                    onChange={(e) => setFormEndDate(e.target.value)}
                  />
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm={12}>
                  <ChakraButton
                    colorScheme="green"
                    onClick={() => handleSearch("default")}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </ChakraButton>
                </Col>
              </Row>

              <Table
                isSearchable={true}
                columns={columns}
                data={data}
                pageSize={15}
                isSortable={true}
                pagination={true}
                paginate={{
                  ...paginate,
                }}
                onPageChange={handlePageChange}
                tableClass="table-nowrap mt-3 table-hover table-striped"
                tfoot={undefined}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes do Log</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {selectedLog && (
              <Box>
                <Text fontWeight="bold" my={1}>
                  Operação:
                </Text>
                <Text
                  my={1}
                  mb={2}
                  color={
                    selectedLog.operation === "CRIAR"
                      ? "green"
                      : selectedLog.operation === "EXCLUIR"
                      ? "red"
                      : selectedLog.operation === "BUSCAR"
                      ? "orange"
                      : selectedLog.operation === "ALTERAR"
                      ? "blue"
                      : "black"
                  }
                >
                  {selectedLog.operation}
                </Text>

                <Divider my={2} />
                <Text fontWeight="bold" my={1}>
                  Data/Hora:
                </Text>
                <Text my={1}>{selectedLog.registeredAt}</Text>

                <Divider my={2} />

                <Text fontWeight="bold" my={1}>
                  Usuário:
                </Text>
                <Text my={1}>{selectedLog.username}</Text>

                <Divider my={2} />

                <Text fontWeight="bold" my={1}>
                  Seção:
                </Text>
                <Text my={1}>{selectedLog.section}</Text>

                {isAdmin && (
                  <Accordion allowToggle defaultIndex={[]} mt={4}>
                    <AccordionItem p={0}>
                      <AccordionButton p={0}>
                        <Box flex="1" textAlign="left" p={0}>
                          <Text my={0} p={0}>
                            Detalhes técnicos
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        {selectedLog.detailsBefore && (
                          <Accordion allowToggle defaultIndex={[]}>
                            <AccordionItem>
                              <h2>
                                <AccordionButton>
                                  <Box flex="1" textAlign="left">
                                    <Text
                                      color="red.500"
                                      fontWeight="bold"
                                      my={0}
                                    >
                                      Antes
                                    </Text>
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                <Box bg="gray.50" p={2} borderRadius="md">
                                  <pre
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {JSON.stringify(
                                      selectedLog.detailsBefore,
                                      null,
                                      2
                                    )}
                                  </pre>
                                </Box>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        )}
                        {selectedLog.detailsAfter && (
                          <Accordion allowToggle defaultIndex={[]}>
                            <AccordionItem>
                              <h2>
                                <AccordionButton>
                                  <Box flex="1" textAlign="left">
                                    <Text
                                      color="green.500"
                                      fontWeight="bold"
                                      my={0}
                                    >
                                      Depois
                                    </Text>
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                <Box bg="gray.50" p={2} borderRadius="md">
                                  <pre
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {JSON.stringify(
                                      selectedLog.detailsAfter,
                                      null,
                                      2
                                    )}
                                  </pre>
                                </Box>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        )}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}

                {selectedLog?.identifier && selectedLog.identifier.length > 0 && (
                  <Box>
                    <Divider my={2} />
                    <Text fontWeight="bold" my={1}>
                      Identificador:
                    </Text>
                    <Text my={1}>
                      {selectedLog?.identifier &&
                        selectedLog?.identifier?.map(
                          (item, index) => item !== null && " - " + item
                        )}
                    </Text>
                  </Box>
                )}

                {selectedLog?.changes !== {} && (
                  <ChakraTable
                    mt={3}
                    variant="simple"
                    size="sm"
                    width={"100%"}
                    maxW={"100%"}
                  >
                    <Thead>
                      <Tr bg="gray.200">
                        <Th>Campo</Th>
                        {selectedLog?.operation === "ALTERAR" && <Th>Antes</Th>}
                        <Th>Modificação</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.entries(selectedLog.changes).map(
                        ([key, value]) => (
                          <Tr key={key}>
                            <Td fontWeight="bold">{key}</Td>
                            {selectedLog?.operation === "ALTERAR" && (
                              <Td color="red.500" wordBreak="break-word">
                                {renderValue(value.antes)}
                              </Td>
                            )}
                            <Td color="green.500" wordBreak="break-word">
                              {renderValue(value.depois)}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </ChakraTable>
                )}
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default Logs;
