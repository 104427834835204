import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Badge,
  Box,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Text,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  Divider,
  Flex,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  StackDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Icon,
  TableContainer,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Card as ChakraCard,
  SimpleGrid,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";
import { format } from "date-fns";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getActionsPermission } from "../../../helpers/api/auth";
import { FaPlus, FaRetweet } from "react-icons/fa";
import LoaderTable from "../../../components/LoaderTable";
import LoaderSimple from "../../../components/LoaderSimple";
/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Financeiro",
  path: "/base/financial/",
  update: "/base/financial/update/",
  apiBase: "/bancas/financial/",
};

/**
 * COMPONENT
 */
const Financial = () => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);

  const toast = useToast();
  const [dataSelect, setDataSelect] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formUnit, setFormUnit] = useState<string>("");
  const [formOperations, setFormOperations] = useState<string>("");
  const [formTransactions, setFormTransactions] = useState<string>("");

  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );

  const [unitData, setUnitData] = useState<any>({});
  const [description, setDescription] = useState("");
  const [transaction, setTransaction] = useState("CREDIT");
  const [account, setAccount] = useState("JB");
  const [type, setType] = useState("BONUS");
  const [value, setValue] = useState<string>("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));
  const actions = getActionsPermission({ permission, role: "Financeiro" });

  const handleOpenModal = async (idUnit: number) => {
    const clearStates = () => {
      setDescription("");
      setValue("");
    };

    /**
     * HANDLERS
     */

    setIsLoading(true);
    await api.create("/bancas/get-saldo", { idUnit })?.then((response) => {
      setUnitData(response?.data);
    });
    setIsLoading(false);

    clearStates();
    onOpen();
  };

  /**
   * ACTIONS
   */

  const preparePayload = (type: string) => {
    let payload = {
      startDate: formStartDate,
      unit: formUnit,
      transactions: formTransactions,
      operations: formOperations,
    };

    return payload;
  };

  const handleSearch = async (type) => {
    const payload = preparePayload(type);

    if (!payload.unit) {
      toast({
        title: "Erro ao buscar registros",
        description: "Informe uma unidade",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);

      toast({
        title: "Erro ao buscar registros",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSaveFinance = async (idUnit: number) => {
    setIsLoading(true);

    const payload = {
      idUnit,
      account,
      description,
      value: parseCurrency(value),
      type,
      transaction,
    };

    await api
      .create("bancas/financial/ajuste", payload)
      .then((response) => {
        toast({
          title: "Registro salvo.",
          description: "O registro foi salvo com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Erro ao salvar o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });

    setIsLoading(false);
  };

  const handleUpdateValue = async (idUnit: number) => {
    if (!description || description === "") {
      toast({
        title: "Erro ao salvar o registro.",
        description: "Informe uma descrição.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!value || value === "" || value === 0) {
      toast({
        title: "Erro ao salvar o registro.",
        description: "Informe um valor.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!window.confirm("Deseja realmente creditar o jogador?")) {
      return;
    }

    await handleSaveFinance(idUnit);
    await handleSearch("default");
    onClose();
  };

  /**
   * TABLE DATE COLUMN
   */
  const DateColumn = ({ ...props }: any) => {
    return (
      <React.Fragment>
        {props?.value && format(new Date(props?.value), "dd/MM/yyyy HH:mm")}
      </React.Fragment>
    );
  };

  /**
   * TABLE ACTIONS
   */
  const ActionColumnDelete = ({ ...props }: any) => {
    const { permission } = useSelector((state: RootState) => ({
      permission: state?.Auth?.user?.userData?.permission,
    }));

    const actions = getActionsPermission({ permission, role: "Financeiro" });
    const object = props?.row?.original;

    /**
     * CUSTOM HOOKS
     */
    const { publish } = useEvent();
    const toast = useToast();

    /**
     * HANDLERS
     */
    const handleClickDelete = async (id: string) => {
      if (window.confirm("Deseja realmente reverter este registro?") === false)
        return;
      setIsLoading(true);
      await api
        .delete(configs.apiBase + id)
        ?.then((response) => {
          handleSearch("default");
          // Exibe um Toast de sucesso
          toast({
            title: "Registro estornado.",
            description: "O registro foi estornado com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          // Exibe um Toast de erro
          toast({
            title: "Erro ao excluir o registro.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
      setIsLoading(false);
    };
    return (
      <React.Fragment>
        {actions.canDelete && object?.operation === "MANUAL" && (
          <Link
            to="#"
            className="action-icon"
            style={{ fontSize: 24 }}
            onClick={() => handleClickDelete(object?.id)}
          >
            {" "}
            <i className="mdi mdi-cash-refund" style={{ color: "#c0392b" }}></i>
          </Link>
        )}
      </React.Fragment>
    );
  };

  const columnsExtrato = [
    {
      Header: "Data",
      accessor: "createdAt",
      sort: true,
      Cell: DateColumn,
    },
    {
      id: "fullName",
      Header: "Nome",
      accessor: "fullname",
      sort: false,
      Cell: ({ value }) => (
        <span style={{ textTransform: "uppercase" }}>{value}</span>
      ),
    },

    {
      Header: "Descrição",
      accessor: "description",
      sort: false,
    },
    {
      Header: "Operação",
      accessor: "operation",
      sort: false,
      Cell: ({ row, value }) => {
        const operacao = value;
        const transacao = row.original.transaction;

        const OperationEnum = {
          COMISSION: "COMISSÃO",
          DEPOSIT: "MANDOU", // MANDOU
          WITHDRAW: "SAQUE", // RECEBEU
          AWARD: "PULE PREMIADA",
          BET: "APOSTA",
          REVERSAL: "ESTORNO",
          MANUAL: "AJUSTE F.",
        };

        const TransactionEnum = {
          CREDIT: "CRÉDITO",
          DEBIT: "DÉBITO",
        };

        let colorScheme2;

        switch (TransactionEnum[transacao]) {
          case "CRÉDITO":
            colorScheme2 = "red";
            break;
          case "DÉBITO":
            colorScheme2 = "green";
            break;
          default:
            colorScheme = "gray";
        }

        let colorScheme;

        switch (OperationEnum[operacao]) {
          case "MANUAL":
            colorScheme = "blue";
            break;
          case "COMISSÃO":
            colorScheme = "red";
            break;
          case "MANDOU":
            colorScheme = "green";
            break;
          case "SAQUE":
            colorScheme = "red";
            break;
          case "PULE PREMIADA":
            colorScheme = "red";
            break;
          case "APOSTA":
            colorScheme = "green";
            break;
          case "ESTORNO":
            colorScheme = "blue";
            break;
          default:
            colorScheme = "gray";
        }
        return (
          <>
            <Badge colorScheme={colorScheme2}>
              {TransactionEnum[transacao]}
            </Badge>{" "}
            <br />
            <Badge colorScheme={colorScheme}>{OperationEnum[operacao]}</Badge>
          </>
        );
      },
    },
    {
      Header: "Conta Origem",
      accessor: "type",
      sort: false,
      Cell: ({ value }) => {
        let colorScheme;
        let type;
        switch (value) {
          case "BONUS":
            colorScheme = "green";
            type = "Bônus";
            break;
          case "PREMIO":
            colorScheme = "red";
            type = "Prêmio";
            break;
          case "SALDO":
            colorScheme = "blue";
            type = "Saldo/Recarga";
            break;
          default:
            colorScheme = "gray";
            type = value;
        }
        return <Badge colorScheme={colorScheme}>{type}</Badge>;
      },
    },
    {
      Header: "Valor",
      accessor: "value",
      sort: true,
      classes: "fw-semibold",
      Cell: ({ value }) => {
        const formattedValue = formatCurrency(value);
        return <span>R$ {formattedValue}</span>;
      },
    },
    {
      Header: "Ações",
      accessor: "actions",
      sort: false,
      classes: "table-action",
      Cell: ActionColumnDelete,
    },
  ];

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              {isLoading && <LoaderSimple />}
              <Row>
                <Col md={3}>
                  <Text
                    fontWeight={"bold"}
                    my={2}
                    color={!formUnit ? "#FF0000" : "#000000"}
                  >
                    Unidade
                  </Text>
                  <Input
                    type="text"
                    placeholder="ID Unidade"
                    border={
                      !formUnit ? "1px solid #ff0000" : "1px solid #000000"
                    }
                    value={formUnit}
                    onChange={(e) => setFormUnit(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <Text fontWeight={"bold"} my={2}>
                    Data
                  </Text>
                  <Input
                    placeholder="Data início"
                    size="md"
                    type="date"
                    value={formStartDate}
                    onChange={(e) => setFormStartDate(e.target.value)}
                  />
                </Col>

                <Col md={3} className="mb-2">
                  <Text fontWeight={"bold"} my={2}>
                    Transação:
                  </Text>
                  <Select
                    placeholder="Todas transações"
                    value={formTransactions}
                    onChange={(e) => setFormTransactions(e.target.value)}
                  >
                    <option value="CREDIT">Crédito</option>
                    <option value="DEBIT">Débito</option>
                  </Select>
                </Col>
                <Col md={3} className="mb-2">
                  <Text fontWeight={"bold"} my={2}>
                    Operação:
                  </Text>
                  <Select
                    placeholder="Todas operações"
                    value={formOperations}
                    onChange={(e) => setFormOperations(e.target.value)}
                  >
                    <option value="DEPOSIT">MANDOU</option>
                    <option value="WITHDRAW">SAQUE</option>
                    <option value="BET">APOSTA</option>
                    <option value="AWARD">PULE PREMIADA</option>
                    <option value="REVERSAL">ESTORNOS</option>
                    <option value="COMISSION">COMISSÃO</option>
                    <option value="MANUAL">AJUSTE F.</option>
                  </Select>
                </Col>

                <Col md={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch("default")}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>
              {data?.saldo?.length > 0 && (
                <>
                  {data?.saldo?.map((item) => (
                    <>
                      <ChakraCard border="1px solid #444" mt={4}>
                        <CardHeader p={2}>
                          <Heading size="md">{item.fullname}</Heading>
                        </CardHeader>

                        <CardBody p={2}>
                          <Stack divider={<StackDivider />} spacing="1">
                            <SimpleGrid columns={{ sm: 2, md: 2, lg: 4 }}>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Limite disponível
                                </Heading>
                                <Text fontSize="sm">
                                  R$ {formatCurrency(item.availableLimit || 0)}
                                </Text>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Bônus
                                </Heading>
                                <Text fontSize="sm" color="#c0392b">
                                  R$ {formatCurrency(item.blockedBalance || 0)}
                                </Text>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Cassino
                                </Heading>
                                <Text fontSize="sm" color="#c0392b">
                                  R${" "}
                                  {formatCurrency(
                                    (item?.cassino?.saldo || 0) +
                                      (item?.cassino?.premio || 0)
                                  )}
                                </Text>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Vendas
                                </Heading>

                                <Flex>
                                  <Text fontSize="sm">
                                    R$ {formatCurrency(item.sell || 0)}
                                  </Text>
                                </Flex>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Comissão
                                </Heading>

                                <Flex color="#c0392b">
                                  <Text fontSize="sm">
                                    R$ {formatCurrency(item.comission || 0)}
                                  </Text>
                                </Flex>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Mandou
                                </Heading>

                                <Flex>
                                  <Text fontSize="sm">
                                    R$ {formatCurrency(item.mandou || 0)}
                                  </Text>
                                </Flex>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Recebeu
                                </Heading>

                                <Flex color="#c0392b">
                                  <Text fontSize="sm">
                                    R$ {formatCurrency(item.recebeu || 0)}
                                  </Text>
                                </Flex>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Créditos
                                </Heading>

                                <Flex>
                                  <Text fontSize="sm">
                                    R$ {formatCurrency(item.credits || 0)}
                                  </Text>
                                </Flex>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Débitos
                                </Heading>

                                <Flex color="#c0392b">
                                  <Text fontSize="sm">
                                    R$ {formatCurrency(item.debits || 0)}
                                  </Text>
                                </Flex>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Prêmios
                                </Heading>

                                <Flex>
                                  <Text fontSize="sm">
                                    R$ {formatCurrency(item.awards || 0)}
                                  </Text>
                                </Flex>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Saldo anterior
                                </Heading>

                                <Flex>
                                  <Text
                                    fontSize="sm"
                                    style={{
                                      color:
                                        item.previousBalance < 0
                                          ? "green"
                                          : "#c0392b",
                                    }}
                                  >
                                    R${" "}
                                    {formatCurrency(
                                      item.previousBalance * -1 || 0
                                    )}
                                  </Text>
                                </Flex>
                              </Box>
                              <Box>
                                <Heading size="xs" textTransform="uppercase">
                                  Saldo atual
                                </Heading>

                                <Flex>
                                  <Text
                                    fontSize="sm"
                                    style={{
                                      color:
                                        item.balance < 0 ? "green" : "#c0392b",
                                    }}
                                  >
                                    R$ {formatCurrency(item.balance * -1 || 0)}
                                  </Text>
                                </Flex>
                              </Box>
                            </SimpleGrid>
                            <Box>
                              {actions.canWrite && (
                                <ChakraButton
                                  mt={2}
                                  onClick={() => handleOpenModal(item.id)}
                                  colorScheme="red"
                                  size="sm"
                                  borderRadius="0"
                                >
                                  AJUSTAR SALDO
                                </ChakraButton>
                              )}

                              <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                  <ModalHeader>
                                    Crédito/Débito da Unidade
                                  </ModalHeader>
                                  <ModalCloseButton />
                                  <ModalBody>
                                    <Text mb={2}>
                                      {item?.fullname?.toUpperCase()}
                                    </Text>

                                    <Box
                                      backgroundColor="gray.100"
                                      border="1px solid #444"
                                      borderRadius={12}
                                      p={3}
                                    >
                                      <Text
                                        my={0}
                                        fontWeight={"bold"}
                                        fontSize={"lg"}
                                        textAlign={"center"}
                                      >
                                        Unidade: {item?.id} - SALDO
                                      </Text>
                                      <Flex>
                                        <Text fontWeight={"bold"} my={0}>
                                          Saldo Recarga:
                                        </Text>
                                        <Spacer />
                                        <Text my={0}>
                                          R${" "}
                                          {formatCurrency(unitData?.saldo || 0)}
                                        </Text>
                                      </Flex>
                                      <Flex>
                                        <Text fontWeight={"bold"} my={0}>
                                          Saldo Bônus:
                                        </Text>
                                        <Spacer />
                                        <Text my={0}>
                                          R${" "}
                                          {formatCurrency(unitData?.bonus || 0)}
                                        </Text>
                                      </Flex>
                                      <Flex>
                                        <Text fontWeight={"bold"} my={0}>
                                          Prêmio (Disp. Saque):
                                        </Text>
                                        <Spacer />
                                        <Text my={0}>
                                          R${" "}
                                          {formatCurrency(
                                            unitData?.premio || 0
                                          )}
                                        </Text>
                                      </Flex>
                                      <Divider my={1} />
                                      <Flex>
                                        <Text fontWeight={"bold"} my={0}>
                                          Total (Recarga + Prêmio + Bônus):
                                        </Text>
                                        <Spacer />
                                        <Text my={0}>
                                          R${" "}
                                          {formatCurrency(unitData?.acc || 0)}
                                        </Text>
                                      </Flex>
                                    </Box>

                                    <FormControl id="account" mt={4}>
                                      <FormLabel>Conta</FormLabel>
                                      <Select
                                        value={account}
                                        onChange={(e) =>
                                          setAccount(e.target.value)
                                        }
                                      >
                                        <option value="JB">JB</option>
                                        <option value="CASSINO">CASSINO</option>
                                      </Select>
                                    </FormControl>

                                    <FormControl id="transaction" mt={4}>
                                      <FormLabel>Transação</FormLabel>
                                      <Select
                                        value={transaction}
                                        onChange={(e) =>
                                          setTransaction(e.target.value)
                                        }
                                      >
                                        <option value="CREDIT">Crédito</option>
                                        <option value="DEBIT">Débito</option>
                                      </Select>
                                    </FormControl>

                                    <FormControl id="type" mt={4}>
                                      <FormLabel>Destino</FormLabel>
                                      <Select
                                        value={type}
                                        onChange={(e) =>
                                          setType(e.target.value)
                                        }
                                      >
                                        {transaction === "CREDIT" ? (
                                          <>
                                            <option value="BONUS">Bônus</option>
                                            <option value="SALDO">
                                              Mandou (Recarga)
                                            </option>
                                            <option value="PREMIO">
                                              Mandou (Pode sacar)
                                            </option>
                                          </>
                                        ) : (
                                          <>
                                            <option value="BONUS">Bônus</option>
                                            <option value="PREMIO">
                                              Prêmio (Recebeu)
                                            </option>
                                            <option value="SALDO">
                                              Recarga
                                            </option>
                                          </>
                                        )}
                                      </Select>
                                    </FormControl>

                                    <FormControl id="description" mt={4}>
                                      <FormLabel>Descrição</FormLabel>
                                      <Input
                                        type="text"
                                        placeholder="Descrição"
                                        value={description}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                      />
                                    </FormControl>

                                    <FormControl id="value" mt={4}>
                                      <FormLabel>Valor R$</FormLabel>
                                      <Input
                                        type="text"
                                        placeholder="R$ 0,00"
                                        value={value}
                                        onChange={(e: any) => {
                                          setValue(
                                            formatCurrency(
                                              parseCurrency(e.target.value)
                                            )
                                          );
                                        }}
                                      />
                                    </FormControl>
                                  </ModalBody>

                                  <ModalFooter>
                                    <Button
                                      colorScheme="orange"
                                      mr={3}
                                      onClick={() => handleUpdateValue(item.id)}
                                    >
                                      Salvar
                                    </Button>
                                  </ModalFooter>
                                </ModalContent>
                              </Modal>
                            </Box>
                          </Stack>
                        </CardBody>
                      </ChakraCard>
                    </>
                  ))}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {data?.extrato?.length > 0 && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                {isLoading && <LoaderSimple />}
                <Table
                  isSearchable={true}
                  isSortable={true}
                  columns={columnsExtrato}
                  data={data?.extrato ?? []}
                  pagination={true}
                  tableClass="table-hover table-striped"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default Financial;
