import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Badge,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Text,
  useToast,
  Box,
  ModalFooter,
  Spacer,
  Td,
  Tr,
  Tbody,
  TableContainer,
  Thead,
  Th,
  Flex,
  ModalBody,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  Table as ChakraTable,
  useDisclosure,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { format } from "date-fns";
import { CheckIcon, RepeatIcon } from "@chakra-ui/icons";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Relatório de Unidades",
  path: "/base/reports/units/",
  update: "/base/reports/units/update/",
  apiBase: "/bancas/report/units",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {props?.value && format(new Date(props?.value), "dd/MM/yyyy HH:mm")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  const status = object?.status;
  const id = object?.id;

  /**
   * HANDLERS
   */
  const updateStatus = (newStatus: string) => {
    if (
      window.confirm("Deseja realmente alterar o status deste registro?") ===
      false
    )
      return;
    dispatch(toggleLoader(true));
    api
      .create(configs.apiBase + id, { status: newStatus })
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro alterado.",
          description: "O registro foi alterado com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao alterar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const listStatus = [
    {
      name: "PAGA",
      menu: "Pagar",
      value: "paid",
      bg: "#00a65a",
      icon: <CheckIcon />,
    },

    // {
    //   name: "ESTORNADO",
    //   menu: "Estornar",
    //   value: "reverse",
    //   bg: "#f39c12",
    //   icon: <NotAllowedIcon />,
    // },
  ];

  const selected = listStatus.find((status) => status.value === object?.status);
  const filterMenu = () => {
    if (status === "paid") {
      return listStatus.filter((status) => status.value !== "paid");
    } else if (status === "reverse") {
      return listStatus.filter((status) => status.value !== "reverse");
    }
    return listStatus;
  };

  return (
    <Menu isLazy>
      <MenuButton disabled={filterMenu().length === 0} width={"100%"}>
        <Badge bgColor={selected?.bg} color="white" width={"100%"}>
          {selected?.name}
        </Badge>
      </MenuButton>
      <MenuList my={0} py={0}>
        {/* MenuItems are not rendered unless Menu is open */}
        <MenuGroup title="Realizar ação" my={1} ml={3}>
          {filterMenu().map((item) => (
            <MenuItem
              my={1}
              icon={item.icon}
              onClick={() => updateStatus(item.value)}
            >
              {item.menu}
            </MenuItem>
          ))}
        </MenuGroup>
        {status === "refused" && (
          <MenuGroup title="Falhas" my={1} ml={3}>
            <MenuItem my={1} icon={<RepeatIcon />}>
              Re-processar
            </MenuItem>
          </MenuGroup>
        )}
      </MenuList>
    </Menu>
  );
};

/**
 * COMPONENT
 */
const UnidadesReport = () => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);
  const toast = useToast();
  const [dataSelect, setDataSelect] = useState<any>(null);
  const [formUnit, setFormUnit] = useState<string>("");
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );

  const getFormattedDate = (date: any, isTime: boolean) => {
    if (isTime) {
      return (
        new Date(date).toLocaleDateString("pt-BR") +
        " " +
        new Date(date).toLocaleTimeString("pt-BR")
      );
    }
    return new Date(date).toLocaleDateString("pt-BR");
  };

  const columns = [
    {
      Header: "Data de Criação",
      accessor: "createdAt",
      sort: true,
      Cell: DateColumn,
    },
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },
    {
      Header: "Nome Completo",
      accessor: "fullname",
      sort: true,
      Cell: ({ row, value }: { value: any; row: any }) => {
        const values = row?.original;
        const now = new Date();
        const unitCreatedAt = new Date(values.unitCreatedAt);
        const diff = Math.abs(now.getTime() - unitCreatedAt.getTime());
        const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

        return (
          <Box>
            {value} <br />
            {format(new Date(values.unitCreatedAt), "dd/MM/yyyy HH:mm")}
            <br />
            {diffDays <= 10 && (
              <Badge colorScheme="red">CADASTRO RECENTE</Badge>
            )}
          </Box>
        );
      },
    },
    {
      Header: "ID Pule",
      accessor: "idPule",
      sort: true,
    },
    {
      Header: "Loteria",
      accessor: "loteria",
      sort: true,
    },
    {
      Header: "Modalidade",
      accessor: "modalidade",
      sort: true,
    },
    {
      Header: "Número",
      accessor: "numero",
      sort: true,
    },
    {
      Header: "Colocação",
      accessor: "colocacao",
      sort: true,
    },
    {
      Header: "Aposta",
      accessor: "amount",
      sort: true,
    },
    {
      Header: "Prêmio",
      accessor: "award",
      sort: true,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: true,
      classes: "fw-semibold",
      Cell: ActionColumn,
    },
  ];

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    dispatch(toggleLoader(true));
    try {
      let dates = {
        startDate: formStartDate,
        endDate: formEndDate,
      };
      let payload = {
        ...dates,
        unit: formUnit,
      };

      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
      await api.get("/bancas/bff/select-data", null).then((response) => {
        setDataSelect(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados de registro.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const preparePayload = (type: string) => {
    let dates = {
      startDate: formStartDate,
      endDate: formEndDate,
    };
    let payload = {
      ...dates,
      unit: formUnit,
    };
    const hoje = format(new Date(), "yyyy-MM-dd");
    const decreaseDate = (qtd: number) => {
      const date = new Date();
      date.setDate(date.getDate() - qtd);
      return format(date, "yyyy-MM-dd");
    };
    if (type === "hoje") {
      payload = {
        ...payload,
        startDate: hoje,
        endDate: hoje,
      };
    } else if (type === "ontem") {
      payload = {
        ...payload,
        startDate: decreaseDate(1),
        endDate: decreaseDate(1),
      };
    } else if (type === "7d") {
      payload = {
        ...payload,
        startDate: decreaseDate(7),
        endDate: hoje,
      };
    } else if (type === "30d") {
      payload = {
        ...payload,
        startDate: decreaseDate(30),
        endDate: hoje,
      };
    } else if (type === "mes") {
      payload = {
        ...payload,
        startDate: hoje.split("-")[0] + "-" + hoje.split("-")[1] + "-01",
        endDate: hoje,
      };
    }
    setFormStartDate(payload.startDate);
    setFormEndDate(payload.endDate);
    return payload;
  };
  const handleSearch = async (type: string) => {
    const payload = preparePayload(type);
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados de registro.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Row>
            <Col md={4}>
              <StatisticsWidget
                variant="danger"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                description="Valor total"
                stats={sumTotalValue(data?.premios, "award")}
                icon="fe-award"
              />
            </Col>
            <Col md={4}>
              <StatisticsWidget
                variant="warning"
                counterOptions={{}}
                description="Quantidade"
                stats={data?.premios?.length || 0}
                icon="fe-bar-chart-line"
              />
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <Flex direction={["column", "row"]} wrap="wrap" gap={2} my={2}>
                <ChakraButton
                  colorScheme="orange"
                  onClick={() => handleSearch("ontem")}
                >
                  Ontem
                </ChakraButton>
                <ChakraButton
                  colorScheme="yellow"
                  onClick={() => handleSearch("hoje")}
                >
                  Hoje
                </ChakraButton>
                <ChakraButton
                  colorScheme="teal"
                  onClick={() => handleSearch("7d")}
                >
                  7D
                </ChakraButton>
                <ChakraButton
                  colorScheme="blue"
                  onClick={() => handleSearch("30d")}
                >
                  30D
                </ChakraButton>
                <ChakraButton
                  colorScheme="red"
                  onClick={() => handleSearch("mes")}
                >
                  Mês
                </ChakraButton>
              </Flex>
              <Row>
                <Col md={6}>
                  <Row>
                    <Text fontWeight={"bold"} my={0} mt={1}>
                      Data inicial:
                    </Text>
                    <Col md={6}>
                      <Input
                        placeholder="Data início"
                        size="md"
                        type="date"
                        value={formStartDate}
                        onChange={(e) => setFormStartDate(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Select
                        placeholder="Todas as unidades"
                        value={formUnit}
                        onChange={(e) => setFormUnit(e.target.value)}
                      >
                        <optgroup label={"Regiões"}>
                          {dataSelect?.regions.map((item: any) => (
                            <option value={"R:" + item.idRegion}>
                              {item.name}
                            </option>
                          ))}
                        </optgroup>
                        <optgroup label={"Cobradores"}>
                          {dataSelect?.collectors.map((item: any) => (
                            <option value={"P:" + item.idUnit}>
                              {item.fullName}
                            </option>
                          ))}
                        </optgroup>
                      </Select>
                    </Col>
                    <Text fontWeight={"bold"} my={0} mt={1}>
                      Data Final:
                    </Text>
                    <Col md={6}>
                      <Input
                        placeholder="Data fim"
                        size="md"
                        type="date"
                        value={formEndDate}
                        onChange={(e) => setFormEndDate(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col md={12}>
                      <Button
                        variant="primary"
                        className="waves-effect waves-light"
                        style={{ backgroundColor: "#4c695c" }}
                        onClick={() => handleSearch("default")}
                      >
                        <i className="fas fa-search me-1"></i> Buscar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Table
                isSearchable={true}
                columns={columns}
                data={data.premios ?? []}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-hover table-striped"
                tfoot={undefined}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UnidadesReport;
