import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Select,
  Spacer,
  Table,
  Tag,
  Td,
  Text,
  Th,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import LoaderTable from "../../../components/LoaderTable";
import { CheckIcon } from "@chakra-ui/icons";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Painel de suporte",
  path: "/support/",
};

/**
 * COMPONENT
 */
const Support = () => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<any[]>([]);
  const [id, setId] = useState<string>("");
  const [idBase, setIdBase] = useState<string>("");
  const [idRegion, setIdRegion] = useState<string>("");
  const [idCollector, setIdCollector] = useState<string>("");
  const [newIdCollector, setNewIdCollector] = useState<string>("");
  const [transformFriends, setTransformFriends] = useState<boolean>(false);
  const [newComission, setNewComission] = useState<string>("");
  const [paymentData, setPaymentData] = useState<any>(null);
  const [twoFaData, setTwoFaData] = useState<any>(null);
  const [blacklistData, setBlacklistData] = useState<any>(null);
  const [bases, setBases] = useState<any[]>([]);
  const [regions, setRegions] = useState<any[]>([]);

  const toast = useToast();

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoading = useCallback(async () => {
    await api.get("/system/support", null)?.then((response) => {
      setData(response?.data ?? []);
    });
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    const getBlacklist = async () => {
      dispatch(toggleLoader(true));
      await api
        .get(`/system/blacklist`, null)
        .then((response) => {
          if (response?.data) {
            setBlacklistData(response.data || {});
          }
        })
        .catch((error) => {
          toast({
            title: "Erro ao buscar dados de blacklist",
            description: error ?? "Erro ao buscar dados de blacklist",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
      dispatch(toggleLoader(false));
    };

    const getBases = async () => {
      dispatch(toggleLoader(true));
      await api
        .get(`/base`, null)
        .then((response) => {
          if (response?.data) {
            setBases(response.data || {});
          }
        })
        .catch((error) => {
          toast({
            title: "Erro ao buscar dados das bases",
            description: error ?? "Erro ao buscar dados das bases",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
      dispatch(toggleLoader(false));
    };

    getBlacklist();
    getBases();

    handlePageInit();
    handlePageLoading();

    const intervalId = setInterval(() => {
      handlePageLoading();
    }, 3000);

    return () => {
      clearInterval(intervalId);
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  useEffect(() => {
    const getRegions = async () => {
      if (!idBase) {
        setRegions([]);
        return;
      }
      dispatch(toggleLoader(true));
      await api
        .get(`/system/regions?idBase=${idBase}`, null)
        .then((response) => {
          if (response?.data) {
            setRegions(response.data || {});
          }
        })
        .catch((error) => {
          toast({
            title: "Erro ao buscar dados das bases",
            description: error ?? "Erro ao buscar dados das bases",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
      dispatch(toggleLoader(false));
    };

    getRegions();
  }, [idBase]);

  const handleRunJob = async (routine: any) => {
    if (window.confirm("Deseja realmente executar essa rotina?") === false) {
      return;
    }

    dispatch(toggleLoader(true));
    await api
      .create(`/system/support/${routine}`, null)
      .then((response) => {
        if (response?.data) {
          toast({
            title: "Sucesso",
            description: "Rotina iniciada com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        toast({
          title: "Erro ao executar rotina",
          description: error ?? "Erro ao executar rotina",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handleGetPayment = async () => {
    dispatch(toggleLoader(true));
    await api
      .create(`/system/recharges`, { id })
      .then((response) => {
        if (response?.data) {
          setPaymentData(response.data || {});
        }
      })
      .catch((error) => {
        toast({
          title: "Erro ao buscar dados de pagamento",
          description: error ?? "Erro ao buscar dados de pagamento",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handleGenerateTwoFa = async () => {
    dispatch(toggleLoader(true));
    await api
      .create(`/system/generate-2fa`, null)
      .then((response) => {
        if (response?.data) {
          setTwoFaData(response.data || {});
        }
      })
      .catch((error) => {
        toast({
          title: "Erro ao gerar código de autenticação",
          description: error ?? "Erro ao gerar código de autenticação",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handleGetExcelUnits = async () => {
    if (!idBase) {
      toast({
        title: "Erro",
        description: "Informe uma base.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    dispatch(toggleLoader(true));
    await api
      .getFile(`/system/helpers/unit/export`, {
        idBase,
        idCollector,
        format: "excel",
      })
      .then((response) => {
        // Obter o nome do arquivo do cabeçalho Content-Disposition
        const contentDisposition = response.headers["content-disposition"];

        let fileName = `UNIDADES - BASE: ${idBase} - ${
          idCollector ? idCollector : ""
        }.xlsx`; // Nome padrão caso o cabeçalho não exista
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length > 1) {
            fileName = fileNameMatch[1]; // Extrair o nome do arquivo
          }
        }

        // Criar um link temporário para baixar o arquivo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Usar o nome extraído
        document.body.appendChild(link);
        link.click();

        // Remover o link após o download
        link?.parentNode.removeChild(link);
      })
      .catch((error) => {
        toast({
          title: "Erro ao exportar dados",
          description: error ?? "Erro ao exportar dados",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handleResetJob = async (type: string) => {
    if (!window.confirm("Deseja realmente librar o job *" + type + "* ?")) {
      return;
    }

    dispatch(toggleLoader(true));
    await api
      .create(`/system/helpers/jobs/unlock`, { type })
      .then((response) => {
        console.log("Liberado.");
      })
      .catch((error) => {
        toast({
          title: "Erro ao liberar lock",
          description: error ?? "Erro ao liberar lock",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handleReprocessPayment = async () => {
    dispatch(toggleLoader(true));

    try {
      const response = await fetch(
        "https://webhook.sistemabarao.com.br/receive/PAGOEXPRESS",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uniqueId: id }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast({
          title: "Pagamento reprocessado",
          description: data?.message || "Reprocessado com sucesso.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Erro ao reprocessar pagamento",
          description: data?.message || "Erro ao reprocessar pagamento",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Erro ao reprocessar pagamento",
        description: "Pagamento não realizado.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  };

  const massEditRegions = async () => {
    if (!idBase) {
      toast({
        title: "Erro",
        description: "Informe uma base.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (!idCollector) {
      toast({
        title: "Erro",
        description: "Informe o promotor para alterar a região.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (
      !window.confirm(
        `Deseja realmente alterar a região de todos os clientes do promotor ${idCollector}?`
      )
    ) {
      return;
    }

    dispatch(toggleLoader(true));
    await api
      .create(`/system/new-region`, { idBase, idRegion, idCollector })
      .then((response) => {
        console.log(response);
        toast({
          title: "Operação em massa realizada!",
          description: response?.data?.message || "Realizado com sucesso.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Erro ao realizar alterações",
          description: error ?? "Erro ao realizar alterações",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const massEditCollectors = async () => {
    if (!idCollector || !newIdCollector) {
      toast({
        title: "Erro",
        description: "Informe os dois promotores para poder alterar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (!idBase) {
      toast({
        title: "Erro",
        description: "Informe uma base.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (
      !window.confirm(
        `Deseja realmente alterar o promotor ${idCollector} para ${newIdCollector}? 
      \n OBS: Lembre-se de que essa ação não pode ser desfeita.
      \n É recomendável gerar a lista de clientes antes de realizar qualquer alteração em massa.
      `
      )
    ) {
      return;
    }

    dispatch(toggleLoader(true));
    await api
      .create(`/system/new-collector`, {
        idBase,
        idCollector,
        newIdCollector,
        transformFriends,
      })
      .then((response) => {
        console.log(response);
        toast({
          title: "Operação em massa realizada!",
          description: response?.data?.message || "Realizado com sucesso.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Erro ao realizar alterações",
          description: error ?? "Erro ao realizar alterações",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const massEditComissions = async () => {
    if (!idCollector || !newComission) {
      toast({
        title: "Erro",
        description: "Informe o promotor e a nova comissão para poder alterar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (!idBase) {
      toast({
        title: "Erro",
        description: "Informe uma base.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (
      !window.confirm(
        `Deseja realmente alterar todos os clientes do promotor *${idCollector}* para a comissão *${newComission}%*? 
      \n OBS: Lembre-se de que essa ação não pode ser desfeita.
      \n É recomendável gerar a lista de clientes antes de realizar qualquer alteração em massa.
      `
      )
    ) {
      return;
    }

    dispatch(toggleLoader(true));
    await api
      .create(`/system/new-comission`, { idBase, idCollector, newComission })
      .then((response) => {
        console.log(response);
        toast({
          title: "Operação em massa realizada!",
          description: response?.data?.message || "Realizado com sucesso.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Erro ao realizar alterações",
          description: error ?? "Erro ao realizar alterações",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      {layout.loading && <LoaderTable />}

      <Row>
        <Col sm={6}>
          <Card>
            <Card.Body>
              <Text fontWeight={600} color="red.500">
                Configuração em Massa:
              </Text>

              <Text fontWeight={"bold"} my={1}>
                BANCA?
              </Text>
              <Select
                placeholder="Banca..."
                textTransform={"uppercase"}
                value={idBase}
                onChange={(e) => setIdBase(e.target.value)}
              >
                {bases?.map((item: any) => (
                  <option value={item.idBase}>{item.title}</option>
                ))}
              </Select>

              <Text fontWeight={"bold"} my={1}>
                PROMOTOR?
              </Text>
              <Input
                type="text"
                placeholder="Promotor"
                value={idCollector}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                  setIdCollector(numericValue);
                }}
              />

              <hr />

              <Text fontWeight={600} color="red.500" my={1}>
                Downloads:
              </Text>

              <Button onClick={handleGetExcelUnits} my={1} colorScheme="blue">
                LISTA DE CLIENTES
              </Button>

              <hr />

              <Text fontWeight={600} color="red.500" my={1}>
                Opções:
              </Text>

              <Box
                borderRadius={2}
                border="1px solid"
                borderColor="red.200"
                p={2}
                my={2}
              >
                <Text fontWeight={"bold"} my={1}>
                  NOVA REGIÃO:
                </Text>
                <Select
                  onChange={(e) => setIdRegion(e.target.value)}
                  value={idRegion}
                  textTransform={"uppercase"}
                >
                  <option value="">NENHUMA REGIÃO</option>

                  {regions?.map((item: any) => (
                    <option value={item.idRegion}>{item.name}</option>
                  ))}
                </Select>

                <Button onClick={massEditRegions} my={1} colorScheme="red">
                  ALTERAR
                </Button>
              </Box>

              <Box
                borderRadius={2}
                border="1px solid"
                borderColor="red.200"
                p={2}
                my={2}
              >
                <Text fontWeight={"bold"} my={1}>
                  NOVA COMISSÃO:
                </Text>

                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    %
                  </InputLeftElement>
                  <Input
                    type="text"
                    placeholder="Comissão"
                    value={newComission}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                      setNewComission(numericValue);
                    }}
                  />
                </InputGroup>

                <Button onClick={massEditComissions} my={1} colorScheme="red">
                  ALTERAR
                </Button>
              </Box>

              <Box
                borderRadius={2}
                border="1px solid"
                borderColor="red.200"
                p={2}
                my={2}
              >
                <Text fontWeight={"bold"} my={1}>
                  NOVO PROMOTOR:
                </Text>
                <Input
                  type="text"
                  placeholder="Promotor"
                  value={newIdCollector}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                    setNewIdCollector(numericValue);
                  }}
                />
                <br />
                <br />
                <Checkbox
                  size="md"
                  colorScheme="green"
                  isChecked={transformFriends}
                  onChange={(e) => setTransformFriends(e.target.checked)}
                >
                  Transformar promotor antigo em rede de amigos? (Opcional)
                </Checkbox>
                <br />
                Marcar a caixa acima, faz com que o promotor antigo seja
                transformado em uma rede de amigos (contendo todos os clientes
                que não tinham amigos antes.).
                <br />
                <br />
                <Button onClick={massEditCollectors} my={1} colorScheme="red">
                  ALTERAR
                </Button>
              </Box>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Text fontWeight={600}>Consultar recarga:</Text>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type="text"
                  placeholder="ID PagoExpress"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleGetPayment}>
                    Buscar
                  </Button>
                </InputRightElement>
              </InputGroup>
              {paymentData ? (
                <>
                  <hr />

                  <Table size="sm">
                    <Tr>
                      <Th>BASE</Th>
                      <Td>{paymentData?.base}</Td>
                    </Tr>
                    <Tr>
                      <Th>UNIDADE</Th>
                      <Td>{paymentData?.idUnit}</Td>
                    </Tr>
                    <Tr>
                      <Th>CONTA</Th>
                      <Td>{paymentData?.account}</Td>
                    </Tr>
                    <Tr>
                      <Th>VALOR</Th>
                      <Td>R$ {formatCurrency(paymentData?.amount || 0)}</Td>
                    </Tr>
                    <Tr>
                      <Th>STATUS</Th>
                      <Td>
                        <Tag
                          colorScheme={
                            paymentData?.status === "paid" ? "green" : "red"
                          }
                        >
                          {paymentData?.status === "paid"
                            ? "PAGO"
                            : paymentData?.status}
                        </Tag>
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>GERADO</Th>
                      <Td>{paymentData?.createdAt}</Td>
                    </Tr>
                    <Tr>
                      <Th>NOTIFICADO</Th>
                      <Td>{paymentData?.updatedAt}</Td>
                    </Tr>
                  </Table>
                  <Center>
                    <Button
                      mt={4}
                      size="xs"
                      colorScheme="red"
                      onClick={handleReprocessPayment}
                    >
                      REPROCESSAR
                    </Button>
                  </Center>
                </>
              ) : null}
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6}>
          <Card>
            <Card.Body>
              <Text fontWeight={600}>Gerar código de autenticação:</Text>
              <Flex gap={1}>
                <Text fontWeight={"bold"} size={22}>
                  {twoFaData?.code}
                </Text>
              </Flex>
              <Flex gap={1}>
                <Button onClick={handleGenerateTwoFa}>Gerar código</Button>
              </Flex>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Table>
                <Tr>
                  <Th>Rotina</Th>
                  <Th>Ação</Th>
                  <Th>JOB</Th>
                </Tr>

                {data?.map((routine, index) => (
                  <Tr key={index}>
                    <Td>{routine?.name}</Td>
                    <Td>
                      {!routine?.already ? (
                        <Link onClick={() => handleRunJob(routine?.type)}>
                          <span className="badge bg-success">Rodar</span>
                        </Link>
                      ) : (
                        <>
                          <span className="badge bg-secondary">
                            {routine?.createdAt}
                          </span>
                          <br />
                          <span className="badge bg-info">Executando</span>
                        </>
                      )}
                    </Td>
                    <Td>
                      <Box
                        cursor={"pointer"}
                        onClick={() => handleResetJob(routine?.type)}
                      >
                        <span className="badge bg-danger">Reiniciar</span>
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col sm="12">
          <Card>
            <Card.Body>
              <Table>
                <Tr>
                  <Th>CPF</Th>
                  <Th>MOTIVO</Th>
                </Tr>

                {blacklistData?.map((blacklist, index) => (
                  <Tr key={index}>
                    <Td>{blacklist?.identifier}</Td>
                    <Td>{blacklist?.observation}</Td>
                  </Tr>
                ))}
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
    </React.Fragment>
  );
};

export default Support;
