import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import { Icon, useToast, Button } from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getActionsPermission } from "../../../helpers/api/auth";
import { FaPlus } from "react-icons/fa";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Regiões",
  path: "/base/regions/",
  create: "/base/regions/new/",
  update: "/base/regions/update/",
  apiBase: "/bancas/regions/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {new Date(props?.value).toLocaleDateString("pt-BR")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Regioes" });
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  /**
   * HANDLERS
   */
  const handleClickDelete = async (id: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete(configs.apiBase + id)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      {actions.canWrite && (
        <Link to={configs.update + object?.idRegion} className="action-icon">
          {" "}
          <i
            className="mdi mdi-square-edit-outline"
            style={{ color: "#2980b9" }}
          ></i>
        </Link>
      )}

      {actions.canDelete && (
        <Link
          to="#"
          className="action-icon"
          onClick={() => handleClickDelete(object?.idRegion)}
        >
          {" "}
          <i className="mdi mdi-delete" style={{ color: "#c0392b" }}></i>
        </Link>
      )}
    </React.Fragment>
  );
};

const columns = [
  {
    Header: "ID",
    accessor: "idRegion",
    sort: true,
    classes: "fw-semibold",
  },
  {
    Header: "Nome",
    accessor: "name",
    sort: false,
  },
  {
    Header: "Data criação",
    accessor: "createdAt",
    sort: false,
    Cell: DateColumn,
  },
  {
    Header: "Ações",
    accessor: "actions",
    sort: false,
    classes: "table-action",
    Cell: ActionColumn,
  },
];

/**
 * COMPONENT
 */
const Regions = () => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Regioes" });
  const dispatch = useDispatch<AppDispatch>();

  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const toast = useToast();
  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));

    await api
      .get(configs.apiBase, null)
      ?.then((response) => {
        setData(response?.data ?? []);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registros",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  }, [setData]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */
  const handleAdd = useCallback(() => {
    navigate(configs.create);
  }, [navigate]);

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="mb-4">
                <Col sm={4}>
                  {actions.canCreate && (
                    <Button
                      mr={2}
                      colorScheme="yellow"
                      leftIcon={<Icon as={FaPlus} />}
                      onClick={handleAdd}
                    >
                      Adicionar Novo
                    </Button>
                  )}
                </Col>
              </Row>

              <Table
                isSearchable={true}
                columns={columns}
                data={data}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-hover table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Regions;
