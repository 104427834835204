import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Badge,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Text,
  useToast,
  Icon,
  Spacer,
  Flex,
  Tooltip,
  Checkbox,
  Box,
  Progress,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  InputGroup,
  SimpleGrid,
  Link,
} from "@chakra-ui/react";

import Select2, { StylesConfig } from "react-select";

import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import {
  CheckIcon,
  ExternalLinkIcon,
  NotAllowedIcon,
  RepeatClockIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";
import {
  FaAccessibleIcon,
  FaHandPointUp,
  FaMoneyBillWaveAlt,
  FaMoneyCheck,
  FaPlus,
  FaSave,
} from "react-icons/fa";
import { getActionsPermission } from "../../../helpers/api/auth";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Fazendinha",
  path: "/base/farm/",
  update: "/base/farm/update/",
  apiBase: "/bancas/view-farms/",
  apiBaseDelete: "/bancas/farms/",
};

const { ptBR } = require("date-fns/locale");

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {format(new Date(props?.value), "dd/MM/yyyy HH:mm")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Fazendinha" });
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  /**
   * HANDLERS
   */
  const handleClickDelete = async (id: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete(configs.apiBaseDelete + id)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      {actions.canDelete && (
        <Link
          to="#"
          className="action-icon"
          onClick={() => handleClickDelete(object?.idFarmOriginal)}
        >
          {" "}
          <i className="mdi mdi-delete" style={{ color: "#c0392b" }}></i>
        </Link>
      )}
    </React.Fragment>
  );
};

const columns = [
  {
    Header: "ID",
    accessor: "idFarmOriginal",
    sort: true,
    classes: "fw-semibold",
    Cell: ({ value }) => {
      return <span>{value.split("-")[0]}</span>;
    },
  },
  {
    Header: "Data criação",
    accessor: "createdAt",
    sort: true,
    Cell: DateColumn,
  },
  {
    Header: "Tipo",
    accessor: "type",
    sort: true,
    classes: "fw-semibold",
  },
  {
    Header: "Loteria",
    accessor: "lotteryCode",
    sort: true,
    classes: "fw-semibold",
    Cell: ({ value, row }) => {
      const data = row.original;
      return (
        <>
          {value} <br />
          {format(new Date(data?.executionAt || ""), "dd/MM/yyyy")}
        </>
      );
    },
  },
  {
    Header: "Preço",
    accessor: "price",
    sort: true,
    classes: "fw-semibold",
    Cell: ({ value }) => {
      const formattedAmount = formatCurrency(value);
      return <span>R$ {formattedAmount}</span>;
    },
  },
  {
    Header: "Prêmio",
    accessor: "award",
    sort: true,
    classes: "fw-semibold",
    Cell: ({ value }) => {
      const formattedAmount = formatCurrency(value);
      return <span>R$ {formattedAmount}</span>;
    },
  },
  {
    Header: "Arrecadado",
    accessor: "remains",
    sort: false,
    classes: "fw-semibold",
    Cell: ({ value, row }) => {
      const data = row.original;
      const arrecadado = data?.price * data?.useds?.length;
      const total = data?.numbers?.length; // 100%
      const remaining = data?.availables?.length;
      const percent = ((total - remaining) * 100) / total;
      return value ? (
        <>
          <Badge colorScheme="green">
            R$ {formatCurrency(arrecadado || 0)}
          </Badge>
          <Progress
            mt={2}
            value={percent}
            size="md"
            colorScheme="blue"
            border={"1px solid #444444"}
            isAnimated
            hasStripe
          />
          {/* <Badge colorScheme="red">RESTAM {data?.availables?.length}</Badge> */}
        </>
      ) : (
        <Badge colorScheme="green">VENDIDA</Badge>
      );
    },
  },
  {
    Header: "Ganhador/a",
    accessor: "unitAwarded",
    sort: true,
    classes: "fw-semibold",
    Cell: ({ value, row }) => {
      const data = row.original;

      return (
        data?.unitAwarded && (
          <Badge colorScheme="blue">{data?.unitAwarded}</Badge>
        )
      );
    },
  },
  {
    Header: "Ações",
    accessor: "actions",
    sort: false,
    classes: "table-action",
    Cell: ActionColumn,
  },
];

/**
 * COMPONENT
 */
const Fazendinha = () => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);
  const [lotteries, setLotteries] = useState([]);
  const [disabledLotteries, setDisabledLotteries] = useState([]);
  const toast = useToast();
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );

  const [idLottery, setIdLottery] = useState("");
  const [executionAt, setExecutionAt] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [valendoDates, setValendoDates] = useState<any[]>([]);
  const [formLotteries, setFormLotteries] = useState<any[]>([]);
  const [price, setPrice] = useState("");
  const [award, setAward] = useState("");
  const [type, setType] = useState("");

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();
  const { publish } = useEvent();
  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    dispatch(toggleLoader(true));
    try {
      let payload = {
        startDate: formStartDate,
        endDate: formEndDate,
      };

      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });

      await api.get("/bancas/lotteries", null)?.then((response) => {
        setLotteries(response.data);
      });

      await api.get("/bancas/disabled-lotteries", null)?.then((response) => {
        setDisabledLotteries(response?.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados da fazendinha.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const preparePayload = (type: string) => {
    let payload = {
      startDate: formStartDate,
      endDate: formEndDate,
    };
    const hoje = format(new Date(), "yyyy-MM-dd");
    const decreaseDate = (qtd: number) => {
      const date = new Date();
      date.setDate(date.getDate() - qtd);
      return format(date, "yyyy-MM-dd");
    };
    if (type === "hoje") {
      payload = {
        ...payload,
        startDate: hoje,
        endDate: hoje,
      };
    } else if (type === "ontem") {
      payload = {
        ...payload,
        startDate: decreaseDate(1),
        endDate: decreaseDate(1),
      };
    } else if (type === "7d") {
      payload = {
        ...payload,
        startDate: decreaseDate(7),
        endDate: hoje,
      };
    } else if (type === "30d") {
      payload = {
        ...payload,
        startDate: decreaseDate(30),
        endDate: hoje,
      };
    } else if (type === "mes") {
      payload = {
        ...payload,
        startDate: hoje.split("-")[0] + "-" + hoje.split("-")[1] + "-01",
        endDate: hoje,
      };
    }
    setFormStartDate(payload.startDate);
    setFormEndDate(payload.endDate);
    return payload;
  };

  const handleSearch = async (type: string) => {
    const payload = preparePayload(type);
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados da fazendinha.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const getSumData = (type) => {
    let arrecadado = 0;
    let restante = 0;
    if (type === "arrecadado") {
      data.map((item) => {
        arrecadado += item?.price * item?.useds?.length;
      });

      return arrecadado;
    }

    if (type === "pendentes") {
      data.map((item) => {
        restante += item?.price * item?.availables?.length;
      });

      return restante;
    }
  };

  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Pules" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleAdd = () => {
    onOpen();
  };

  const handleSaveFarm = async () => {
    const payload = {
      type,
      executions: valendoDates.map((date) => date.raw),
      lotteries: formLotteries.map((lot) => lot.value),
      price: parseCurrency(price),
      award: parseCurrency(award),
    };

    if (
      !payload.type ||
      payload.executions.length === 0 ||
      payload.lotteries.length === 0 ||
      payload.price <= 0 ||
      payload.award <= 0
    ) {
      toast({
        title: "Erro ao salvar fazendinha.",
        description: "Preencha todos os campos.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (
      window.confirm(
        "Você tem certeza de que todos os dados estão corretos? *Não é possível cancelar a fazendinha*! Se tens dúvida, clique em cancelar e verifique novamente."
      ) === false
    )
      return;

    dispatch(toggleLoader(true));

    try {
      await api.create("/bancas/farms", payload).then((response) => {
        toast({
          title: "Fazendinha salva com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
    } catch (e) {
      toast({
        title: "Erro ao salvar fazendinha.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    publish("deleteItem", null);
    dispatch(toggleLoader(false));
    onClose();
  };

  const getListValendoDates = () => {
    const today = format(new Date(), "yyyy-MM-dd");
    let valendo = [];
    valendo.push({
      raw: today,
      day: today.split("-")[2],
      month: today.split("-")[1],
    });
    for (let i = 1; i < 8; i++) {
      const now = new Date();
      const addDate = format(
        new Date(now.setDate(now.getDate() + i)),
        "yyyy-MM-dd"
      );
      valendo.push({
        raw: addDate,
        day: addDate.split("-")[2],
        month: addDate.split("-")[1],
      });
    }

    return valendo;
  };

  const isSelected = (item) => {
    return valendoDates.some((date) => date.raw === item.raw);
  };

  const daysEnable = valendoDates.map((date) => {
    const weekday = format(new Date(date.raw + "T00:00:00"), "EEEE");
    return weekday.toLowerCase();
  });

  const getLotteriesToDays = () => {
    const filtered1 = lotteries?.lotteries?.filter(
      (lotterie) =>
        lotterie.code !== "LTI" &&
        lotterie.code !== "SEN" &&
        lotterie.code !== "QUIN" &&
        lotterie.code !== "LOTO" &&
        lotterie.code !== "UR15" &&
        lotterie.code !== "UR21"
    );

    const filtered = filtered1?.filter((lotterie: any) => {
      return !disabledLotteries.some(
        (disabled: any) => disabled?.idLottery === lotterie.idLottery
      );
    });

    //order by idCategory

    const ordered = filtered?.sort((a, b) => {
      if (a.idCategory > b.idCategory) {
        return 1;
      }
      if (a.idCategory < b.idCategory) {
        return -1;
      }
      return 0;
    });

    // console.log(filtered);

    const filteredLotteries = ordered?.filter((lottery) => {
      try {
        const executionDays = JSON.parse(lottery.executionAt);
        return daysEnable.every((day) => executionDays.includes(day));
      } catch (e) {
        console.error(
          `Error parsing executionAt for lottery: ${lottery.id}`,
          e
        );
        return false;
      }
    });

    const newLotteries = filteredLotteries?.filter(
      (lottery) => !lottery.title.includes("MALUCA")
    );

    return newLotteries?.map((lottery) => {
      return {
        value: lottery.idLottery,
        label: lottery.title,
      };
    });
  };

  const options = getLotteriesToDays();

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Row>
            <Col md={4}>
              <StatisticsWidget
                variant="success"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                description="Arrecadado"
                stats={getSumData("arrecadado")}
                icon="mdi mdi-currency-usd"
              />
            </Col>
            <Col md={4}>
              <StatisticsWidget
                variant="danger"
                counterOptions={{
                  prefix: "R$ ",
                  preserveValue: true,
                  decimal: ",",
                  separator: ".",
                }}
                description="Pendentes"
                stats={getSumData("pendentes")}
                icon="fe-bar-chart-line"
              />
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <Row className="mb-4">
                <Col sm={4}>
                  {actions.canWrite && (
                    <ChakraButton
                      mr={2}
                      colorScheme="yellow"
                      leftIcon={<Icon as={FaPlus} />}
                      onClick={handleAdd}
                    >
                      Adicionar Novo
                    </ChakraButton>
                  )}

                  <Modal isOpen={isOpen} onClose={onClose} size="lg">
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Nova Fazendinha</ModalHeader>
                      <ModalCloseButton />

                      <ModalBody>
                        <Flex direction="column" gap={2}>
                          <Flex direction="column" gap={1}>
                            <Text fontWeight={"bold"} my={0}>
                              Datas valendo:
                            </Text>
                            <Flex>
                              <SimpleGrid
                                columns={[2, 4]}
                                spacing={1}
                                width={"100%"}
                                mb={4}
                              >
                                {getListValendoDates().map((item) => (
                                  <Box
                                    bg={
                                      isSelected(item) ? "#ECC94B" : "#cccccc"
                                    }
                                    color={
                                      isSelected(item) ? "#000000" : "#000000"
                                    }
                                    cursor={"pointer"}
                                    p={4}
                                    textAlign={"center"}
                                    borderRadius={12}
                                    onClick={() => {
                                      setFormLotteries([]);
                                      if (isSelected(item)) {
                                        setValendoDates(
                                          valendoDates?.filter(
                                            (date) => date.raw !== item.raw
                                          )
                                        );
                                        return;
                                      }

                                      setValendoDates([...valendoDates, item]);
                                    }}
                                  >
                                    <Text fontWeight={"bold"} my={0}>
                                      {item.day}/{item.month}
                                    </Text>{" "}
                                    {format(
                                      new Date(item.raw + "T00:00:00"),
                                      "EEEE",
                                      { locale: ptBR }
                                    )
                                      .split("-")[0]
                                      .toUpperCase()}
                                  </Box>
                                ))}
                              </SimpleGrid>
                            </Flex>
                          </Flex>

                          {valendoDates.length > 0 && (
                            <Flex direction="column" gap={1}>
                              <Text fontWeight={"bold"} my={0}>
                                Loteria:
                              </Text>

                              <br />
                              <Flex>
                                <Checkbox
                                  isChecked={
                                    options.length === formLotteries.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFormLotteries(options);
                                      return;
                                    }
                                    setFormLotteries([]);
                                  }}
                                >
                                  Todas
                                </Checkbox>
                              </Flex>
                              <br />
                              <Select2
                                closeMenuOnSelect={false}
                                isMulti
                                options={options}
                                value={formLotteries}
                                onChange={(e) => setFormLotteries(e)}
                              />
                            </Flex>
                          )}

                          <Flex direction="column" gap={1}>
                            <Text fontWeight={"bold"} my={0}>
                              Fazendinha:
                            </Text>
                            <Select
                              placeholder="Selecione..."
                              size="md"
                              value={type}
                              onChange={(e) => setType(e.target.value)}
                            >
                              <option value="GROUP">GRUPO</option>
                              <option value="TEN">DEZENA</option>
                              <option value="CENT">CENTENA</option>
                              {/* <option value="HALF_GROUP">MEIO GRUPO</option> */}
                            </Select>
                          </Flex>

                          <Flex direction="column" gap={1}>
                            <Text fontWeight={"bold"} my={0}>
                              Preço:
                            </Text>
                            <InputGroup>
                              <Input
                                placeholder="Preço por número"
                                type="text"
                                size="md"
                                value={price}
                                onChange={(e) =>
                                  setPrice(
                                    formatCurrency(
                                      parseCurrency(e.target.value || "0")
                                    )
                                  )
                                }
                              />
                            </InputGroup>
                          </Flex>

                          <Flex direction="column" gap={1}>
                            <Text fontWeight={"bold"} my={0}>
                              Prêmio:
                            </Text>
                            <InputGroup>
                              <Input
                                placeholder="Prêmio da fazendinha"
                                type="text"
                                size="md"
                                value={award}
                                onChange={(e) =>
                                  setAward(
                                    formatCurrency(
                                      parseCurrency(e.target.value || "0")
                                    )
                                  )
                                }
                              />
                            </InputGroup>
                          </Flex>
                        </Flex>
                      </ModalBody>

                      <ModalFooter>
                        <ChakraButton
                          colorScheme="yellow"
                          leftIcon={<Icon as={FaSave} />}
                          onClick={handleSaveFarm}
                        >
                          Salvar
                        </ChakraButton>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Col>
              </Row>

              <Flex direction={["column", "row"]} wrap="wrap" gap={2} my={2}>
                <ChakraButton
                  colorScheme="orange"
                  onClick={() => handleSearch("ontem")}
                >
                  Ontem
                </ChakraButton>
                <ChakraButton
                  colorScheme="yellow"
                  onClick={() => handleSearch("hoje")}
                >
                  Hoje
                </ChakraButton>
                <ChakraButton
                  colorScheme="teal"
                  onClick={() => handleSearch("7d")}
                >
                  7D
                </ChakraButton>
                <ChakraButton
                  colorScheme="blue"
                  onClick={() => handleSearch("30d")}
                >
                  30D
                </ChakraButton>
                <ChakraButton
                  colorScheme="red"
                  onClick={() => handleSearch("mes")}
                >
                  Mês
                </ChakraButton>
              </Flex>

              <Row>
                <Text fontWeight={"bold"} my={0} mt={1}>
                  Data inicial:
                </Text>
                <Col md={3}>
                  <Input
                    placeholder="Data início"
                    size="md"
                    type="date"
                    value={formStartDate}
                    onChange={(e) => setFormStartDate(e.target.value)}
                  />
                </Col>

                <Text fontWeight={"bold"} my={0} mt={1}>
                  Data Final:
                </Text>
                <Col md={3}>
                  <Input
                    placeholder="Data fim"
                    size="md"
                    type="date"
                    value={formEndDate}
                    onChange={(e) => setFormEndDate(e.target.value)}
                  />
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch("default")}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>

              <Table
                calculateInput={"amount"}
                isSearchable={true}
                columns={columns}
                data={data}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-hover table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Fazendinha;
