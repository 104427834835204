import { APICore } from "./apiCore";

const api = new APICore();

function login(params: { username: string; password: string; code?: string }) {
  const baseUrl = "/login/";

  return api.create(`${baseUrl}`, params);
}

function logout() {
  // const baseUrl = "/logout/";

  // return api.create(`${baseUrl}`, {});
  return Promise.resolve({});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";

  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password/";

  return api.create(`${baseUrl}`, params);
}

function getActionsPermission({
  permission,
  role,
}: {
  permission: any;
  role: string;
}) {
  const actions = permission?.roles.find(
    (item: any) => item?.title === role
  )?.level;

  return {
    canRead: actions.includes("Read"),
    canCreate: actions.includes("Create"),
    canWrite: actions.includes("Write"),
    canDelete: actions.includes("Delete"),
  };
}

export { login, logout, signup, forgotPassword, getActionsPermission };
